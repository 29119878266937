/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import ReviewContactForm from "./ReviewContactForm";
import ReviewDocumentForm from "./ReviewDocumentForm";
import ReviewPersonalForm from "./ReviewPersonalForm";
import ReviewWorkForm from "./ReviewWorkForm";
import ReviewEduForm from "./ReviewEduForm";
import ReviewResume from "./ReviewResume";
import ReviewSkills from "./ReviewSkills";
import { responseApi } from "../CheckApi";
import { useNavigate } from "react-router-dom";
import { Button, CircularProgress } from "@mui/material";
import axios from "axios";
import Finished from "../Finished";
import REACT_APP from "../../environment";
import { useTranslation } from 'react-i18next';

export default function Review() {
    let navigate = useNavigate();
    const [finish, setFinish] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [send, setSend] = useState({
        per: "disabled",
        work: "disabled",
        edu: "disabled",
        con: "disabled",
        doc: "disabled",
        resume: "disabled",
    });

    useEffect(() => {
        responseApi(navigate);
    }, []);

    const submit = () => {
        const save = Object.values(send).some((el) => el === "save");
        if (save) {
            toast.warning("All Forms mast be Saved! Some form has not been saved!");
            return;
        }
        handleNext();
    };

    const handleNext = () => {
        setLoadingBtn(true);
        axios
            .put(`${REACT_APP.API_URL}/api/hrp/v2/onb/check`, { onb_user: 0 }, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setFinish(true);
                setLoadingBtn(false);
                setTimeout(() => {
                    sessionStorage.removeItem("token");
                    navigate("/login");
                }, 4000);
            })
            .catch((err) => {
                toast.error("An error occurred while go to next page!");
                setLoadingBtn(false);
            });
    };
    const{t}=useTranslation()

    return finish ? (
        <Finished />
    ) : (
        <>
            <ReviewPersonalForm send={send} set={setSend} />
            <ReviewWorkForm send={send} set={setSend} />
            <ReviewEduForm send={send} set={setSend} />
            <ReviewContactForm send={send} set={setSend} />
            <ReviewDocumentForm send={send} set={setSend} />
            <ReviewResume send={send} set={setSend} />
            <ReviewSkills />
            <div className="col-12 container text-center pb-4 pt-3">
                <Button
                    className="w-25"
                    variant="contained"
                    disabled={loadingBtn}
                    onClick={() => submit()}
                    startIcon={loadingBtn && <CircularProgress size="0.9rem" />}
                >
                    {t("Submit")}
                </Button>
            </div>
            <ToastContainer />
            {loadingBtn ? <div className="hrp-overlay"></div> : null}
        </>
    );
}
