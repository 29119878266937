import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { validateSignUp } from "../validateSchema";
import REACT_APP from "../../environment";
import { useTranslation } from 'react-i18next';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { toast, ToastContainer } from "react-toastify";

import "./Login.css";
import axios from "axios";
import { CircularProgress } from "@mui/material";

const theme = createTheme();

export default function SignUp() {
    let navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const{t}=useTranslation()
    const search = useLocation().search;
    const token = new URLSearchParams(search).get("token");

    const [visibleIcon, setVisibleIcon] = React.useState(true);
    const [visibleIcon2, setVisibleIcon2] = React.useState(true);

    const handleSubmit = (event) => {
        setLoading(true);
        axios
            .post(
                `${REACT_APP.AUTH_URL}/api/hrp/v2/onboarding/signup`,
                {
                    email: event.email,
                    password: event.password,
                },
                { headers: { Authorization: token } }
            )
            .then((res) => {
                navigate("/login");
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                toast.error(<p className="qcont">{err.response.data.errors[0].message}</p>);
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs" className="py-5">
                <CssBaseline />
                <ToastContainer autoClose={1500} />
                <Box sx={{ marginTop: 8, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t("Sign up")}
                    </Typography>

                    <Formik
                        initialValues={{ email: "", password: "", confirmPassword: "" }}
                        validationSchema={validateSignUp}
                        onSubmit={async (values) => {
                            handleSubmit(values);
                            return new Promise((res) => setTimeout(res, 500));
                        }}
                    >
                        {({ values, errors }) => (
                            <Form autoComplete="off" className="mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Field fullWidth name="email" disabled={loading} label={t("Email Address")} autoComplete="email" component={TextField} />
                                    </Grid>

                                    <Grid item xs={12} className="input_div">
                                        <Field
                                            fullWidth
                                            label={t("Password")}
                                            name="password"
                                            disabled={loading}
                                            component={TextField}
                                            autoComplete="password"
                                            type={visibleIcon ? "password" : "text"}
                                        />
                                        {visibleIcon ? (
                                            <VisibilityOffIcon onClick={() => setVisibleIcon((prev) => !prev)} />
                                        ) : (
                                            <RemoveRedEyeIcon onClick={() => setVisibleIcon((prev) => !prev)} />
                                        )}
                                    </Grid>

                                    <Grid item xs={12} className="input_div">
                                        <Field
                                            fullWidth
                                            disabled={loading}
                                            name="confirmPassword"
                                            label={t("Confirm password")}
                                            component={TextField}
                                            type={visibleIcon2 ? "password" : "text"}
                                            autoComplete="new password"
                                        />
                                        {visibleIcon2 ? (
                                            <VisibilityOffIcon onClick={() => setVisibleIcon2((prev) => !prev)} />
                                        ) : (
                                            <RemoveRedEyeIcon onClick={() => setVisibleIcon2((prev) => !prev)} />
                                        )}
                                    </Grid>
                                </Grid>
                                <Button
                                    fullWidth
                                    type="submit"
                                    className="mt-5"
                                    disabled={loading}
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    startIcon={loading && <CircularProgress size="0.9rem" />}
                                >
                                    {t("Sign up")}
                                </Button>
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <Link href="/login" variant="body2">
                                           {t("Already have an account? Sign in")}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
