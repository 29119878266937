import axios from "axios";
import { toast } from "react-toastify";
import REACT_APP from "../environment";

export const responseApi = async (navigate) => {
    if (!sessionStorage.getItem("token")) {
        navigate("/login");
        return;
    }

    await fetch(`${REACT_APP.API_URL}/api/hrp/v2/onb/check`, { headers: { Authorization: sessionStorage.getItem("token") } })
        .then((res) => res.json())
        .then((data) => {
            const checking = data.data;
            if (checking.personal === "0") navigate("/");
            else if (checking.work_history === "0") navigate("/workhistory");
            else if (checking.education === "0") navigate("/education");
            else if (checking.contact === "0") navigate("/contact");
            else if (checking.skill === "0") navigate("/skill");
            else if (checking.document === "0") navigate("/document");
            else navigate("/review");
        });
};

export const handleBack = ({ body, setDisable, setBackLoading, navigate, toaster }) => {
    setDisable(true);
    setBackLoading(true);
    axios
        .put(
            `${REACT_APP.API_URL}/api/hrp/v2/onb/check`,
            {
                body: 0,
            },
            { headers: { Authorization: sessionStorage.getItem("token") } }
        )
        .then((res) => {
            setDisable(false);
            setBackLoading(false);
            navigate("/");
        })
        .catch((err) => toast.error(toaster));
};
