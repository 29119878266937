/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, CircularProgress, Grid, MenuItem, Typography, Tooltip } from "@mui/material";
import { Field, FieldArray, Form, Formik } from "formik";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { educationSchema } from "../validateSchema";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FileSaver from "file-saver";
import axios from "axios";
import REACT_APP from "../../environment";
import { useTranslation } from 'react-i18next';
var errorMsg = false;
export default function ReviewEduForm({ send, set }) {
    let navigate = useNavigate();
    const [disable, setDisable] = useState(true);
    const [loading, setLoading] = useState(true);
    const [docType, setDocType] = useState(false);
    const [education, setEducation] = useState([]);
    const [backLoading, setBackLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);
    const [educationType, setEducationType] = useState([]);

    useEffect(() => {
        getEducation();
        getEducationType();
    }, []);

    const getEducationType = () => {
        axios(`${REACT_APP.API_URL}/api/hrp/v2/onb/university`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        }).then((res) => setEducationType(res.data.data));
    };

    const getEducation = () => {
        setLoading(true);
        fetch(`${REACT_APP.API_URL}/api/hrp/v2/onb/education`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((response) => response.json())
            .then((res) => {
                setEducation(res.data);
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const submit = (value) => {
        console.log(value);

        setDisable(true);
        value.educations.forEach((el, i) => {
            const body = new FormData();
            body.append("id", el.id);
            body.append("university_id", el.university_id);
            body.append("major", el.major);
            body.append("is_complete", el.is_complete);
            body.append("years_of_edu", el.years_of_edu);
            body.append("degree_type", el.degree_type);
            body.append("start_date", el.start_date);
            body.append("end_date", el.end_date);
            body.append("diploma", el.diploma);
            if (!el.is_complete) {
                body.delete("diploma");
            }

            axios
                .post(`${REACT_APP.API_URL}/api/hrp/v2/onb/edu/update`, body, {
                    headers: { Authorization: sessionStorage.getItem("token") },
                })
                .then((res) => {
                    setDisable(true);
                    set((send) => ({ ...send, edu: null }));
                    getEducation();
                })
                .catch((err) => {
                    setDisable(false);
                    set((send) => ({ ...send, edu: "save" }));
                    toast.error("An error occurred!");
                    errorMsg = true;
                    return 0;
                });
            if (errorMsg) return;
        });
    };

    const getFile = (id) => {
        const response = async () => {
            setLoadingModal(true);
            setDocType(false);
            await fetch(`${REACT_APP.HRP_DOC_API}/file/${id}`, {
                headers: {
                    "client-id": REACT_APP.CLIENT_ID,
                    secret: REACT_APP.SECRET,
                    "Content-type": "application/pdf",
                },
            })
                .then((res) => res.blob())
                .then((blob) => {
                    if (blob.type === "application/pdf" || /image/.test(blob.type)) {
                        setLoadingModal(false);
                        setTimeout(() => {
                            document.querySelector("#education_doc").src = window.URL.createObjectURL(blob);
                        });
                    } else {
                        setLoadingModal(false);
                        setDocType(true);
                        setTimeout(() => {
                            FileSaver.saveAs(blob, "diploma");
                        });
                    }
                });
        };
        response();
    };

    const handleEdit = () => {
        setDisable(false);
        set((send) => ({ ...send, edu: "save" }));
    };

    const handleBack = () => {
        setDisable(true);
        setBackLoading(true);
        axios
            .put(
                `${REACT_APP.API_URL}/api/hrp/v2/onb/check`,
                {
                    education: 0,
                },
                { headers: { Authorization: sessionStorage.getItem("token") } }
            )
            .then((res) => {
                setDisable(false);
                setBackLoading(false);
                navigate("/");
            })
            .catch((err) => toast.error("An error occurred while back to Education page!"));
    };

    const deleteFile = (values, idx) => {
        if (values.educations[idx].diploma && values.educations[idx].is_complete) {
            setDisable(true);
            setImageLoading(true);
            axios
                .delete(`${REACT_APP.API_URL}/api/hrp/v2/onb/education/${values.educations[idx].id}`, {
                    headers: { Authorization: sessionStorage.getItem("token") },
                })
                .then((res) => {
                    getEducation();
                    setImageLoading(false);
                    setDisable(false);
                })
                .catch((err) => {
                    toast.error("An error occurred!");
                    setImageLoading(false);
                    setDisable(false);
                    return 0;
                });
        }
    };

    const handleDiploma = (e, end_date, id) => {
        if (e.target.value) {
            setImageLoading(true);
            setDisable(true);
            const file = new FormData();
            file.append("id", id);
            file.append("end_date", end_date);
            file.append("diploma", e.target.files[0]);
            axios
                .patch(`${REACT_APP.API_URL}/api/hrp/v2/onb/education`, file, { headers: { Authorization: sessionStorage.getItem("token") } })
                .then((res) => {
                    setImageLoading(false);
                    getEducation();
                    setDisable(false);
                })
                .catch((err) => {
                    toast.error("An error occurred!");
                    setImageLoading(false);
                    setDisable(false);
                    e.target.files[0] = "";
                    return 0;
                });
        }
    };
    const{t}=useTranslation()
    return (
        <>
            <div className="d-flex justify-content-center flex-column container py-3">
                <div className="py-2">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="text-center" style={disable ? { color: "#aaa" } : {}}>
                                {t("Educations")}
                            </h5>
                        </div>
                        {loading ? (
                            <div className="w-100 d-flex justify-content-center align-items-center py-5 my-5">
                                <CircularProgress />
                            </div>
                        ) : education.length !== 0 ? (
                            <Card>
                                <CardContent style={{ padding: "24px" }}>
                                    <Formik
                                        initialValues={{ educations: education }}
                                        validationSchema={educationSchema}
                                        onSubmit={async (values) => {
                                            submit(values);
                                            return new Promise((res) => setTimeout(res, 500));
                                        }}
                                    >
                                        {({ values, errors, isSubmitting }) => (
                                            <Form autoComplete="off">
                                                <Grid container direction="column" spacing={2}>
                                                    <FieldArray name="educations">
                                                        {({ push, remove }) => (
                                                            <React.Fragment>
                                                                {values.educations?.map((_, index) => (
                                                                    <Grid container item key={index} spacing={2}>
                                                                        <Grid item container spacing={2} xs={12}>
                                                                            <Grid item xs={12} style={index === 0 ? { display: "none" } : { display: "block" }}>
                                                                                <p
                                                                                    className="text-center mt-2"
                                                                                    style={
                                                                                        disable ? { fontWeight: "500", color: "#aaa" } : { fontWeight: "500" }
                                                                                    }
                                                                                >
                                                                                    {t("Educations")} {index + 1}
                                                                                </p>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    select
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`educations.${index}.university_id`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Select University")}
                                                                                >
                                                                                    {educationType.map((el) => (
                                                                                        <MenuItem key={el.id} value={el.id}>
                                                                                            {el.name}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Field>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`educations[${index}].major`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Major")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`educations[${index}].years_of_edu`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Years of education")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={6} className="mt-sm-3">
                                                                                <label className="mat_label">{t("Date of enrollment")}</label>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    type="date"
                                                                                    name={`educations.${index}.start_date`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    format="dd/MM/yyyy"
                                                                                />
                                                                            </Grid>
                                                                            {_.is_complete && (
                                                                                <Grid item xs={12} sm={6} className="mt-sm-3">
                                                                                    <label className="mat_label">{t("Date of graduation")}</label>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        disabled={disable}
                                                                                        type="date"
                                                                                        variant="standard"
                                                                                        name={`educations[${index}].end_date`}
                                                                                        component={TextField}
                                                                                        format="dd/MM/yyyy"
                                                                                    />
                                                                                </Grid>
                                                                            )}

                                                                            <Grid item xs={12} sm={6} className="mt-sm-4">
                                                                                <Field
                                                                                    className="mt-sm-1"
                                                                                    select
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`educations[${index}].degree_type`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Select your degree")}
                                                                                >
                                                                                    <MenuItem value="bachelor">Bachelor's</MenuItem>
                                                                                    <MenuItem value="master">Master's</MenuItem>
                                                                                    <MenuItem value="phd">PHD</MenuItem>
                                                                                </Field>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={6} className="d-grid col-sm-8 col-md-6">
                                                                                <Field
                                                                                    name={`educations[${index}].is_complete`}
                                                                                    defaultValue={_.is_complete}
                                                                                    type="checkbox"
                                                                                    disabled={disable}
                                                                                    component={CheckboxWithLabel}
                                                                                    onClick={() => deleteFile(values, index)}
                                                                                    Label={{ label: t("Have you completed your education?") }}
                                                                                />
                                                                                {_.diploma && (
                                                                                    <div className="w-100 d-flex align-items-end">
                                                                                        <div id="img_span" className="m-0">
                                                                                            <span
                                                                                                data-bs-toggle="modal"
                                                                                                data-bs-target="#eduDocModal"
                                                                                                onClick={() => getFile(_.diploma)}
                                                                                            >
                                                                                                {t("diploma")}
                                                                                            </span>
                                                                                            {imageLoading && <CircularProgress size="0.9rem" />}
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                                {!_.diploma && _.is_complete && (
                                                                                    <Field
                                                                                        fullWidth
                                                                                        className="col-md-6"
                                                                                        name={`educations[${index}].diploma`}
                                                                                        component={TextField}
                                                                                        type="file"
                                                                                        variant="standard"
                                                                                        disabled={disable}
                                                                                        id={`educations${index}diploma`}
                                                                                        startIcon={imageLoading && <CircularProgress size="0.9rem" />}
                                                                                        inputProps={{ accept: "application/msword, application/pdf" }}
                                                                                        onChange={(e) => handleDiploma(e, _.end_date, _.id)}
                                                                                    />
                                                                                )}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}

                                                                <Grid item>
                                                                    {typeof errors.educations === "string" ? (
                                                                        <Typography color="error">{errors.educations}</Typography>
                                                                    ) : null}
                                                                </Grid>

                                                                <Grid item xs={12}>
                                                                    {send.edu === "disabled" && (
                                                                        <Button className="float-end" variant="contained" onClick={() => handleEdit()}>
                                                                            {disable ? t("Edit") : "Disabled"}
                                                                        </Button>
                                                                    )}
                                                                    {send.edu === "save" && (
                                                                        <>
                                                                            <Tooltip title="Back to Education page and add new field" arrow>
                                                                                <Button
                                                                                    startIcon={
                                                                                        backLoading ? <CircularProgress size="0.9rem" /> : <ArrowBackIcon />
                                                                                    }
                                                                                    disabled={disable}
                                                                                    variant="outlined"
                                                                                    color="secondary"
                                                                                    onClick={() => handleBack()}
                                                                                >
                                                                                    {t("Back")}
                                                                                </Button>
                                                                            </Tooltip>
                                                                            <Button
                                                                                className="float-end"
                                                                                disabled={isSubmitting || disable}
                                                                                variant="contained"
                                                                                type="submit"
                                                                                startIcon={(isSubmitting || disable) && <CircularProgress size="0.9rem" />}
                                                                            >
                                                                                {isSubmitting || disable ? t("Saving") : t("Save")}
                                                                            </Button>
                                                                        </>
                                                                    )}
                                                                </Grid>
                                                            </React.Fragment>
                                                        )}
                                                    </FieldArray>
                                                </Grid>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardContent>
                            </Card>
                        ) : (
                            <div className="w-100 text-center py-5 my-5">
                                <p style={{ color: "#1976d2", marginBottom: "20px" }}>{t("Education is Empty")}</p>
                                <Tooltip title="Back to Education page and add new field" arrow>
                                    <Button
                                        startIcon={backLoading ? <CircularProgress size="0.9rem" /> : <ArrowBackIcon />}
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => handleBack()}
                                    >
                                        {t("Back")}
                                    </Button>
                                </Tooltip>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="modal fade" id="eduDocModal" tabIndex="-1" aria-labelledby="eduDocModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content bg-white">
                        <div className="modal-header py-1">
                            <h5 className="modal-title" id="eduDocModalLabel">
                                {t("Diploma")}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body img-fluid">
                            {loadingModal ? (
                                <div className="spinner-border text-info my-5" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : docType ? (
                                <h2 className="m-auto my-5">File downloaded!</h2>
                            ) : (
                                <iframe id="education_doc" src="" alt="" height="750" width="900" />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
