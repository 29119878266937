import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, CircularProgress, Grid, MenuItem, Tooltip, Typography } from "@mui/material";
import { Field, FieldArray, Form, Formik } from "formik";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TextField } from "formik-material-ui";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { contactSchema } from "../validateSchema";
import REACT_APP from "../../environment";
import { useTranslation } from 'react-i18next';

export default function ReviewContactForm({ send, set }) {
    let navigate = useNavigate();
    const [disable, setDisable] = useState(true);
    const [loading, setLoading] = useState(true);
    const [contact, setContact] = useState([]);
    const [backLoading, setBackLoading] = useState(false);
    const [relationship, setRelationship] = useState([]);

    useEffect(() => {
        getContact();
        getRelationType();
    }, []);

    const getRelationType = () => {
        axios(`${REACT_APP.API_URL}/api/hrp/v2/onb/contact/types`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        }).then((res) => setRelationship(res.data.data));
    };

    const getContact = () => {
        setLoading(true);
        axios
            .get(`${REACT_APP.API_URL}/api/hrp/v2/onb/emergency_contacts`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setContact(res.data.data);
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const submit = (values) => {
        axios
            .put(`${REACT_APP.API_URL}/api/hrp/v2/onb/emergency_contacts`, values.contacts, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setDisable(true);
                set((send) => ({ ...send, con: null }));
                getContact();
            })
            .catch((err) => toast.error("An error occurred!"));
    };

    const handleEdit = () => {
        setDisable(false);
        set((send) => ({ ...send, con: "save" }));
    };

    const handleBack = () => {
        setDisable(true);
        setBackLoading(true);
        axios
            .put(
                `${REACT_APP.API_URL}/api/hrp/v2/onb/check`,
                {
                    contact: 0,
                },
                { headers: { Authorization: sessionStorage.getItem("token") } }
            )
            .then((res) => {
                setDisable(false);
                setBackLoading(false);
                navigate("/");
            })
            .catch((err) => toast.error("An error occurred while back to Contact page!"));
    };
    const{t}=useTranslation()

    return (
        <div className="d-flex justify-content-center flex-column container py-3">
            <div className="py-2">
                <div className="card">
                    <div className="card-header">
                        <h5 className="text-center" style={disable ? { color: "#aaa" } : {}}>
                            {t("Contacts")}
                        </h5>
                    </div>
                    <div>
                        {loading ? (
                            <div className="w-100 d-flex justify-content-center align-items-center py-5 my-5">
                                <CircularProgress />
                            </div>
                        ) : contact?.length !== 0 ? (
                            <Card>
                                <CardContent style={{ padding: "24px" }}>
                                    <Formik
                                        initialValues={{ contacts: contact }}
                                        validationSchema={contactSchema}
                                        onSubmit={async (values) => {
                                            submit(values);
                                            return new Promise((res) => setTimeout(res, 100));
                                        }}
                                    >
                                        {({ values, errors, isSubmitting }) => (
                                            <Form autoComplete="off">
                                                <Grid container direction="column" spacing={2}>
                                                    <FieldArray name="contacts">
                                                        {({ push, remove }) => (
                                                            <React.Fragment>
                                                                {values.contacts?.map((_, index) => (
                                                                    <Grid container item key={index} spacing={2}>
                                                                        <Grid item container spacing={2} xs={12}>
                                                                            <Grid item xs={12} style={index === 0 ? { display: "none" } : { display: "block" }}>
                                                                                <p
                                                                                    className="text-center mt-2"
                                                                                    style={
                                                                                        disable ? { fontWeight: "500", color: "#aaa" } : { fontWeight: "500" }
                                                                                    }
                                                                                >
                                                                                    Contact {index + 1}
                                                                                </p>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`contacts[${index}].first_name`}
                                                                                    pattern={"[0-9]"}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label="First name"
                                                                                    required
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`contacts[${index}].last_name`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label="Last name"
                                                                                    required
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`contacts[${index}].email`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label="Email"
                                                                                    required
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={6}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`contacts[${index}].phone`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label="Phone"
                                                                                    required
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={6}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    select
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    required
                                                                                    name={`contacts[${index}].type_of_relationship`}
                                                                                    label="Relationship"
                                                                                >
                                                                                    {relationship.map((el) => (
                                                                                        <MenuItem key={el.id} value={el.id}>
                                                                                            {el.relationship}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Field>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}

                                                                <Grid item>
                                                                    {typeof errors.contacts === "string" ? (
                                                                        <Typography color="error">{errors.contacts}</Typography>
                                                                    ) : null}
                                                                </Grid>

                                                                <Grid item xs={12}>
                                                                    {send.con === "disabled" && (
                                                                        <Button className="float-end" variant="contained" onClick={() => handleEdit()}>
                                                                            {disable ? "Edit" : "Disabled"}
                                                                        </Button>
                                                                    )}
                                                                    {send.con === "save" && (
                                                                        <>
                                                                            <Tooltip title="Back to Contact page and add new field" arrow>
                                                                                <Button
                                                                                    startIcon={
                                                                                        backLoading ? <CircularProgress size="0.9rem" /> : <ArrowBackIcon />
                                                                                    }
                                                                                    disabled={disable}
                                                                                    variant="outlined"
                                                                                    color="secondary"
                                                                                    onClick={() => handleBack()}
                                                                                >
                                                                                    Back
                                                                                </Button>
                                                                            </Tooltip>
                                                                            <Button
                                                                                className="float-end"
                                                                                disabled={isSubmitting || disable}
                                                                                variant="contained"
                                                                                type="submit"
                                                                                startIcon={isSubmitting ? <CircularProgress size="0.9rem" /> : undefined}
                                                                            >
                                                                                {isSubmitting ? "Saving" : "Save"}
                                                                            </Button>
                                                                        </>
                                                                    )}
                                                                </Grid>
                                                            </React.Fragment>
                                                        )}
                                                    </FieldArray>
                                                </Grid>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardContent>
                            </Card>
                        ) : (
                            <div className="w-100 text-center py-5 my-5">
                                <p style={{ color: "#1976d2", marginBottom: "20px" }}>{t("Contact is Empty")}</p>
                                <Tooltip title="Back to Contact page and add new field" arrow>
                                    <Button
                                        startIcon={backLoading ? <CircularProgress size="0.9rem" /> : <ArrowBackIcon />}
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => handleBack()}
                                    >
                                        {t("Back")}
                                    </Button>
                                </Tooltip>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
