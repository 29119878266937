/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, CircularProgress, Grid, Tooltip, Typography } from "@mui/material";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { array, object, string } from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import FileSaver from "file-saver";
import REACT_APP from "../../environment";
import regex from "tts-hrp-val-lib";
import { useTranslation } from 'react-i18next';

export default function ReviewDocumentForm({ send, set }) {
    const [disable, setDisable] = useState(true);
    const [loading, setLoading] = useState(true);
    const [document, setDocument] = useState([]);
    const [docType, setDocType] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);

    useEffect(() => {
        getDocument();
    }, []);

    const getDocument = () => {
        setLoading(true);
        axios
            .get(`${REACT_APP.API_URL}/api/hrp/v2/onb/documents`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setDocument(res.data.data);
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const submit = (values) => {
        console.log(values.documents);
        if (values.documents.length === 0) {
            setDisable(true);
            set((send) => ({ ...send, doc: null }));
            return;
        }

        values.documents?.forEach((element, i) => {
            const body = new FormData();
            body.append("diploma", window.document.querySelector(`#documents${i}document`).files[0]);
            body.append("document_name", element.document_name);

            axios
                .post(`${REACT_APP.API_URL}/api/hrp/v2/onb/document`, body, { headers: { Authorization: sessionStorage.getItem("token") } })
                .then((res) => {
                    getDocument();
                    setDisable(true);
                    set((send) => ({ ...send, doc: null }));
                })
                .catch((err) => toast.error("An error occurred!"));
        });
    };

    const getImage = (id) => {
        setDocType(false);
        setLoadingModal(true);
        fetch(`${REACT_APP.HRP_DOC_API}/file/${id}`, {
            headers: {
                "client-id": REACT_APP.CLIENT_ID,
                secret: REACT_APP.SECRET,
                "Content-type": "application/pdf",
            },
        })
            .then((res) => res.blob())
            .then((blob) => {
                if (blob.type === "application/pdf" || /image/.test(blob.type)) {
                    setLoadingModal(false);
                    setTimeout(() => {
                        window.document.querySelector("#rasm").src = window.URL.createObjectURL(blob);
                    });
                } else {
                    setLoadingModal(false);
                    setDocType(true);
                    setTimeout(() => {
                        FileSaver.saveAs(blob, "document");
                    });
                }
            })
            .catch((err) => setLoadingModal(false));
    };

    const removeFile = async (uuid) => {
        const response = await axios.delete(`${REACT_APP.API_URL}/api/hrp/v2/onb/document/${uuid}`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        });
        console.log(response.data);
        getDocument();
    };
    const{t}=useTranslation()

    return (
        <>
            <div className="d-flex justify-content-center flex-column container py-3">
                <div className="py-2">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="text-center" style={disable ? { color: "#aaa" } : {}}>
                                {t("Documents")}
                            </h5>
                        </div>
                        <div>
                            {loading ? (
                                <div className="w-100 d-flex justify-content-center align-items-center py-5 my-5">
                                    <CircularProgress />
                                </div>
                            ) : (
                                <Card>
                                    <CardContent style={{ padding: "24px" }}>
                                        <div className="row mb-4">
                                            {document.map((el, i) => (
                                                <div className="col-md-6" key={i}>
                                                    <div className="w-100 d-flex align-items-end justify-content-center">
                                                        <div id="img_span">
                                                            <span
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                                onClick={() => getImage(el.document_id)}
                                                            >
                                                                {el.document_name}
                                                            </span>

                                                            {!disable && (
                                                                <Tooltip title="delete" arrow>
                                                                    <button
                                                                        type="button"
                                                                        className="btn-close"
                                                                        aria-label="Close"
                                                                        onClick={() => removeFile(el.document_id)}
                                                                    ></button>
                                                                </Tooltip>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <Formik
                                            initialValues={{ documents: [] }}
                                            validationSchema={object({
                                                documents: array(
                                                    object({
                                                        document_name: string()
                                                            .matches(regex.file_name, { message: "Invalid name" })
                                                            .required(t("Document name is required")),
                                                        document: string().required(t("Document is required")),
                                                    })
                                                ),
                                            })}
                                            onSubmit={async (values) => {
                                                submit(values);
                                                return new Promise((res) => setTimeout(res, 500));
                                            }}
                                        >
                                            {({ values, errors, isSubmitting }) => (
                                                <Form autoComplete="off">
                                                    <Grid container direction="column" spacing={2}>
                                                        <FieldArray name="documents">
                                                            {({ push, remove }) => (
                                                                <React.Fragment>
                                                                    {values.documents.length === 0 && document.length === 0 ? (
                                                                        <div className="text-center">
                                                                            <p style={{ color: "#1976d2", marginLeft: "15px" }}>{("Documents is Empty")}</p>
                                                                        </div>
                                                                    ) : (
                                                                        values.documents?.map((_, index) => (
                                                                            <Grid container item key={index} spacing={2}>
                                                                                <Grid
                                                                                    item
                                                                                    container
                                                                                    spacing={4}
                                                                                    xs={12}
                                                                                    className="d-flex justify-content-center"
                                                                                >
                                                                                    <Grid item xs={12} md={5}>
                                                                                        <Field
                                                                                            fullWidth
                                                                                            variant="standard"
                                                                                            name={`documents[${index}].document_name`}
                                                                                            component={TextField}
                                                                                            label={t("Document Name")}
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item xs={12} md={5}>
                                                                                        <Field
                                                                                            fullWidth
                                                                                            variant="standard"
                                                                                            type="file"
                                                                                            name={`documents[${index}].document`}
                                                                                            id={`documents${index}document`}
                                                                                            component={TextField}
                                                                                            label={t("Document")}
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item xs={12} md={2}>
                                                                                        <Button
                                                                                            color="error"
                                                                                            variant="contained"
                                                                                            className="float-end mt-3"
                                                                                            disabled={isSubmitting}
                                                                                            onClick={() => remove(index)}
                                                                                        >
                                                                                            {t("Delete")}
                                                                                        </Button>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        ))
                                                                    )}

                                                                    <Grid item>
                                                                        {typeof errors.contacts === "string" ? (
                                                                            <Typography color="error">{errors.contacts}</Typography>
                                                                        ) : null}
                                                                    </Grid>

                                                                    <Grid item xs={12}>
                                                                        {send.doc === "disabled" && (
                                                                            <Button
                                                                                className="float-end"
                                                                                variant="contained"
                                                                                onClick={() => {
                                                                                    setDisable(false);
                                                                                    set((send) => ({ ...send, doc: "save" }));
                                                                                }}
                                                                            >
                                                                                {disable ? t("Edit") : t("Disabled")}
                                                                            </Button>
                                                                        )}
                                                                        {send.doc === "save" && (
                                                                            <div
                                                                                className={
                                                                                    values.documents.length !== 0 || document.length !== 0 ? "" : "text-center"
                                                                                }
                                                                            >
                                                                                <Button
                                                                                    disabled={isSubmitting}
                                                                                    variant={
                                                                                        values.documents.length !== 0 || document.length !== 0
                                                                                            ? "contained"
                                                                                            : "outlined"
                                                                                    }
                                                                                    color={
                                                                                        values.documents.length !== 0 || document.length !== 0
                                                                                            ? "primary"
                                                                                            : "secondary"
                                                                                    }
                                                                                    onClick={() => push({ document_name: "", document: "" })}
                                                                                >
                                                                                    {t("Add documents")}
                                                                                </Button>
                                                                                {(values.documents.length !== 0 || document.length !== 0) && (
                                                                                    <Button
                                                                                        className="float-end"
                                                                                        disabled={isSubmitting}
                                                                                        variant="contained"
                                                                                        type="submit"
                                                                                        startIcon={isSubmitting && <CircularProgress size="0.9rem" />}
                                                                                    >
                                                                                        {isSubmitting ? t("Saving") : t("Save")}
                                                                                    </Button>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </Grid>
                                                                </React.Fragment>
                                                            )}
                                                        </FieldArray>
                                                    </Grid>
                                                </Form>
                                            )}
                                        </Formik>
                                    </CardContent>
                                </Card>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content bg-white">
                        <div className="modal-header py-1">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Document
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body img-fluid">
                            {loadingModal ? (
                                <div className="spinner-border text-info my-5" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : docType ? (
                                <h2 className="m-auto my-5">File downloaded!</h2>
                            ) : (
                                // eslint-disable-next-line jsx-a11y/iframe-has-title
                                <iframe id="rasm" src="" alt="" height="750" width="900" />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
