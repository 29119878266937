/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, Checkbox, CircularProgress, FormControlLabel, Grid, MenuItem, Typography } from "@mui/material";
import { Field, FieldArray, Form, Formik } from "formik";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import { responseApi } from "../CheckApi";
import Loader from "../Loader";
import REACT_APP from "../../environment";
import { useTranslation } from 'react-i18next';

import { educationSchema } from "../validateSchema";
import { emptyEducation } from "../emptyValues";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

export default function Education() {
    let navigate = useNavigate();
    const [next, setNext] = useState(false);
    const [loader, setLoader] = useState(true);
    const [loading, setLoading] = useState(false);
    const [education, setEducation] = useState([]);
    const [nextLoading, setNextLoading] = useState(false);
    const [educationType, setEducationType] = useState([]);

    useEffect(() => {
        responseApi(navigate);
        getEducation();
        getEducationType();
    }, []);

    const getEducationType = () => {
        axios(`${REACT_APP.API_URL}/api/hrp/v2/onb/university`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        }).then((res) => setEducationType(res.data.data));
    };

    const getEducation = () => {
        setLoader(true);
        axios
            .get(`${REACT_APP.API_URL}/api/hrp/v2/onb/education`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setEducation(res.data.data);
                setLoader(false);
            })
            .catch((err) => {
                console.log(err);
                setLoader(false);
                toast.error("An error occurred while getting educations!");
            });
    };

    const checkFile = (values, index) => {
        if (values.educations[index].is_complete) {
            values.educations[index].diploma = "";
            values.educations[index].end_date = "";
        }
    };

    const submit = (value) => {
        setLoading(true);
        const result = value.educations.filter((el) => !el.id);
        if (result.length === 0) {
            handleNext();
            return;
        }

        result.forEach((el, i) => {
            const body = new FormData();
            body.append("university_id", el.university_id);
            body.append("major", el.major);
            body.append("is_complete", el.is_complete);
            body.append("years_of_edu", el.years_of_edu);
            body.append("degree_type", el.degree_type);
            body.append("start_date", el.start_date);
            body.append("end_date", el.end_date);
            body.append("diploma", document.querySelector(`#educations${i}diploma`)?.files[0] || "");
            if (!el.is_complete) {
                body.delete("end_date");
                body.delete("diploma");
            }

            axios
                .post(`${REACT_APP.API_URL}/api/hrp/v2/onb/education`, body, {
                    headers: { Authorization: sessionStorage.getItem("token") },
                })
                .then((res) => {
                    navigate("/contact");
                    setLoading(false);
                })
                .catch((err) => {
                    toast.error("An error occurred while saving educations!");
                    setLoading(false);
                });
        });
    };

    const handleNext = () => {
        setNextLoading(true);
        axios
            .put(
                `${REACT_APP.API_URL}/api/hrp/v2/onb/check`,
                {
                    education: 1,
                },
                { headers: { Authorization: sessionStorage.getItem("token") } }
            )
            .then((res) => {
                setNextLoading(false);
                navigate("/");
            })
            .catch((err) => toast.error("An error occurred while go to next page!"));
    };
    const{t}=useTranslation()
    return loader ? (
        <Loader />
    ) : (
        <>
            {education.length === 0 && (
                <div disabled={next} className="d-flex justify-content-center container pt-4">
                    <div className="col-sm-6">
                        <FormControlLabel
                            control={<Checkbox disabled={nextLoading} onClick={() => setNext((prev) => !prev)} />}
                            label={t("If you don't have Education! Click here")}
                        />
                    </div>
                    <div className="col-sm-6">
                        <Button
                            onClick={() => handleNext()}
                            className="float-end"
                            disabled={!next || nextLoading}
                            variant="contained"
                            startIcon={nextLoading ? <CircularProgress size="0.9rem" /> : ""}
                        >
                            {t("Next")}
                        </Button>
                    </div>
                </div>
            )}
            {!next && (
                <div className="d-flex justify-content-center flex-column container py-3">
                    <ToastContainer autoClose={1500} />
                    {loading ? <div className="hrp-overlay"></div> : null}
                    <div className="py-2">
                        <div className="card">
                            <div className="card-header">
                                {" "}
                                {console.log(educationType)}
                                <h5 className="text-center">{t("Educations")}</h5>
                            </div>
                            <Card>
                                <CardContent style={{ padding: "20px" }}>
                                    <Formik
                                        initialValues={education?.length !== 0 ? { educations: education } : { educations: [emptyEducation] }}
                                        validationSchema={educationSchema}
                                        onSubmit={async (values) => {
                                            submit(values);
                                            return new Promise((res) => setTimeout(res, 100));
                                        }}
                                    >
                                        {({ values, errors, isSubmitting }) => (
                                            <Form autoComplete="off">
                                                <Grid container direction="column" spacing={2}>
                                                    <FieldArray name="educations">
                                                        {({ push, remove }) => (
                                                            <React.Fragment>
                                                                {values.educations.map((_, index) => (
                                                                    <Grid container item key={index} spacing={2}>
                                                                        <Grid item container spacing={2} xs={12}>
                                                                            <Grid item xs={12} style={index === 0 ? { display: "none" } : { display: "block" }}>
                                                                                <p className="text-center fw-bold">{t("Education")} {index + 1}</p>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    select
                                                                                    fullWidth
                                                                                    variant="standard"
                                                                                    name={`educations.${index}.university_id`}
                                                                                    component={TextField}
                                                                                    disabled={_.id}
                                                                                    label={t("Select University")}
                                                                                >
                                                                                    {educationType.map((el) => (
                                                                                        <MenuItem key={el.id} value={el.id}>
                                                                                            {el.name}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Field>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    variant="standard"
                                                                                    name={`educations[${index}].major`}
                                                                                    component={TextField}
                                                                                    disabled={_.id}
                                                                                    label={t("Major")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    variant="standard"
                                                                                    name={`educations[${index}].years_of_edu`}
                                                                                    label={t("Years of education")}
                                                                                    component={TextField}
                                                                                    disabled={_.id}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={6} className="mt-sm-3">
                                                                                <label className="mat_label">{t("Date of enrollment")}</label>
                                                                                <Field
                                                                                    fullWidth
                                                                                    variant="standard"
                                                                                    type="date"
                                                                                    name={`educations.${index}.start_date`}
                                                                                    component={TextField}
                                                                                    format="dd/MM/yyyy"
                                                                                    disabled={_.id}
                                                                                />
                                                                            </Grid>
                                                                            {_.is_complete && (
                                                                                <Grid item xs={12} sm={6} className="mt-sm-3">
                                                                                    <label className="mat_label">{t("Date of graduation")}</label>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        type="date"
                                                                                        name={`educations[${index}].end_date`}
                                                                                        component={TextField}
                                                                                        format="dd/MM/yyyy"
                                                                                        disabled={_.id}
                                                                                    />
                                                                                </Grid>
                                                                            )}

                                                                            <Grid item xs={12} sm={6} className={_.is_complete ? "mt-sm-4" : "mt-sm-2"}>
                                                                                <Field
                                                                                    className="mt-sm-1"
                                                                                    select
                                                                                    fullWidth
                                                                                    variant="standard"
                                                                                    name={`educations[${index}].degree_type`}
                                                                                    component={TextField}
                                                                                    disabled={_.id}
                                                                                    label={t("Select your degree")}
                                                                                >
                                                                                    <MenuItem value="bachelor">Bachelor's</MenuItem>
                                                                                    <MenuItem value="master">Master's</MenuItem>
                                                                                    <MenuItem value="phd">PHD</MenuItem>
                                                                                </Field>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={6} className="d-grid col-sm-8 col-md-6">
                                                                                <Field
                                                                                    type="checkbox"
                                                                                    disabled={_.id}
                                                                                    component={CheckboxWithLabel}
                                                                                    onClick={() => checkFile(values, index)}
                                                                                    name={`educations[${index}].is_complete`}
                                                                                    Label={{ label:t( "Have you completed your education?") }}
                                                                                />

                                                                                {_.diploma && _.id && (
                                                                                    <div className="w-100 d-flex align-items-end">
                                                                                        <div id="img_span" className="m-0">
                                                                                            {t("diploma")}
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                                {_.is_complete && !_.id ? (
                                                                                    <Field
                                                                                        className="col-md-12"
                                                                                        component={TextField}
                                                                                        name={`educations[${index}].diploma`}
                                                                                        disabled={_.id}
                                                                                        variant="standard"
                                                                                        inputProps={{ accept: "application/msword, application/pdf" }}
                                                                                        id={`educations${index}diploma`}
                                                                                        type="file"
                                                                                    />
                                                                                ) : (
                                                                                    <Field className="col-md-12 form-control invisible" />
                                                                                )}
                                                                            </Grid>

                                                                            <Grid item xs={12} sm={12}>
                                                                                <Button
                                                                                    style={index === 0 || _?.id ? { display: "none" } : { display: "block" }}
                                                                                    color="error"
                                                                                    disabled={isSubmitting}
                                                                                    onClick={() => remove(index)}
                                                                                    variant="contained"
                                                                                >
                                                                                    {t("Delete")}
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}

                                                                <Grid item>
                                                                    {typeof errors.educations === "string" ? (
                                                                        <Typography color="error">{errors.educations}</Typography>
                                                                    ) : null}
                                                                </Grid>

                                                                <Grid item container spacing={2} xs={12}>
                                                                    <Grid item xs={6} sm={6}>
                                                                        <Button
                                                                            disabled={isSubmitting}
                                                                            variant="contained"
                                                                            onClick={() => push(emptyEducation)}
                                                                        >
                                                                            {t("Add")}
                                                                        </Button>
                                                                    </Grid>

                                                                    <Grid item xs={6} sm={6}>
                                                                        <Button
                                                                            type="submit"
                                                                            className="float-end"
                                                                            disabled={isSubmitting}
                                                                            variant="contained"
                                                                            startIcon={isSubmitting && <CircularProgress size="0.9rem" />}
                                                                        >
                                                                            {isSubmitting ? t("Saving") :t( "Save")}
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </React.Fragment>
                                                        )}
                                                    </FieldArray>
                                                </Grid>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
