/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, CircularProgress, FormControlLabel, FormLabel, Grid, MenuItem, Radio, Typography } from "@mui/material";
import { Field, FieldArray, Form, Formik } from "formik";
import { RadioGroup, TextField } from "formik-material-ui";
import { responseApi } from "../CheckApi";
import Loader from "../Loader";
import REACT_APP from "../../environment";

import { personalSchema } from "../validateSchema";
import { emptyAddress, emptyDependency, emptyPersonal } from "../emptyValues";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from 'react-i18next';

export default function Personal() {
    let navigate = useNavigate();
    const [loader, setLoader] = useState(true);
    const [country, setCountry] = useState([]);
    const [regions, setRegions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [relationship, setRelationship] = useState([]);

    useEffect(() => {
        async function responseData() {
            setLoader(true);
            await responseApi(navigate);
            await getRelationType();
            await getCountry();
            await getRegions();
            setLoader(false);
        }
        responseData();
    }, []);

    const getRelationType = () => {
        axios(`${REACT_APP.API_URL}/api/hrp/v2/onb/dependent/types`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        }).then((res) => setRelationship(res.data.data));
    };

    const getCountry = () => {
        axios(`${REACT_APP.COMMON_API}/tts/api/v01/countries`, {
            headers: { "client-id": REACT_APP.COMMON_ID, secret: REACT_APP.COMMON_SECRET },
        }).then((res) => setCountry(res.data.data));
    };

    const getRegions = () => {
        axios(`${REACT_APP.COMMON_API}/tts/api/v01/region/14ed6cfc-d787-45ba-b44d-84ac06e37935`, {
            headers: { "client-id": REACT_APP.COMMON_ID, secret: REACT_APP.COMMON_SECRET },
        }).then((res) => setRegions(res.data.data));
    };

    const submit = (value) => {
        setLoading(true);
        if (!value.middle_name) value.middle_name = undefined;
        if (!value.tin) value.tin = undefined;
        if (!value.nationality) value.nationality = undefined;
        if (!value.birth_place.address2) value.birth_place.address2 = undefined;
        if (!value.birth_place.zip_code) value.birth_place.zip_code = undefined;

        value.dependency.filter((el) => {
            if (!el.tin) el.tin = undefined;
            if (!el.middle_name) el.middle_name = undefined;
        });
        value.addresses.filter((el) => {
            if (!el.address2) el.address2 = undefined;
            if (!el.zip_code) el.zip_code = undefined;
            if (!el.to_date) el.to_date = undefined;
        });

        axios
            .post(`${REACT_APP.API_URL}/api/hrp/v2/onb/personal`, value, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                navigate("/workHistory");
                setLoading(false);
            })
            .catch((err) => {
                toast.error("An error occurred while saving personal!");
                setLoading(false);
            });
    };
    const{t}=useTranslation()

    return loader ? (
        <Loader />
    ) : (
        <div className="d-flex justify-content-center flex-column container py-3">
            <ToastContainer autoClose={1500} />
            {loading ? <div className="hrp-overlay"></div> : null}
            <div className="py-2">
                <div className="card">
                    <div className="card-header">
                        <h5 className="text-center">{t("personal")}</h5>
                    </div>
                    <div>
                        <Card>
                            <CardContent style={{ padding: "20px" }}>
                                <Formik
                                    initialValues={emptyPersonal}
                                    validationSchema={personalSchema}
                                    onSubmit={async (values) => {
                                        submit(values);
                                        return new Promise((res) => setTimeout(res, 500));
                                    }}
                                >
                                    {({ values, errors, handleBlur, touched, isSubmitting }) => (
                                        <Form autoComplete="off">
                                            <Grid container direction="column" spacing={2}>
                                                <Grid item container spacing={2} xs={12}>
                                                    <Grid item xs={12} sm={4}>
                                                        <Field fullWidth variant="standard" name="first_name" component={TextField} label={t("First Name")} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <Field fullWidth variant="standard" name="last_name" component={TextField} label={t("Last Name")} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <Field fullWidth variant="standard" name="middle_name" component={TextField} label={t("Middle Name")} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <label className="mat_label">{t("Birth Date")}</label>
                                                        <Field
                                                            fullWidth
                                                            variant="standard"
                                                            type="date"
                                                            name="birth_date"
                                                            component={TextField}
                                                            format="dd/mm/yyyy"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <Field fullWidth variant="standard" name="tin" component={TextField} label={t("TIN")} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <Field fullWidth variant="standard" name="nationality" component={TextField} label={t("Nationality")} />
                                                    </Grid>

                                                    <Grid item xs={12} sm={3}>
                                                        <Field fullWidth variant="standard" name="citizenship" component={TextField} label={t("Citizenship")} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <Field fullWidth variant="standard" name="home_phone" component={TextField} label={t("Home phone")} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <Field fullWidth variant="standard" name="cell_phone" component={TextField} label={t("Cell phone")} />
                                                    </Grid>

                                                    <Field component={RadioGroup} name="gender" className="ps-sm-3 col-sm-3 mt-2">
                                                        <FormLabel component="legend">{t("Gender")}</FormLabel>
                                                        <Grid>
                                                            <FormControlLabel
                                                                value="male"
                                                                label={t("male")}
                                                                control={<Radio disabled={isSubmitting} />}
                                                                disabled={isSubmitting}
                                                            />
                                                            <FormControlLabel
                                                                value="female"
                                                                label={t("female")}
                                                                control={<Radio disabled={isSubmitting} />}
                                                                disabled={isSubmitting}
                                                            />
                                                        </Grid>
                                                        {touched.gender && errors.gender && <p className="error">{errors.gender}</p>}
                                                    </Field>

                                                    <Grid item xs={12}>
                                                        <p className="text-center mt-3">{t("Birth place")}</p>
                                                    </Grid>

                                                    <Grid item xs={12} sm={4}>
                                                        <Field
                                                            fullWidth
                                                            select
                                                            variant="standard"
                                                            component={TextField}
                                                            disableCloseOnSelect
                                                            onClick={() => (values.birth_place.region = "")}
                                                            name="birth_place.country"
                                                            label={t("Select Country")}
                                                        >
                                                            {country.map((el) => (
                                                                <MenuItem key={el.id} value={el.name}>
                                                                    {el.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Field>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        {values.birth_place.country === "Uzbekistan" ? (
                                                            <Field
                                                                fullWidth
                                                                select
                                                                variant="standard"
                                                                component={TextField}
                                                                name="birth_place.region"
                                                                label={t("Select Region")}
                                                            >
                                                                {regions.map((el) => (
                                                                    <MenuItem key={el.id} value={el.name}>
                                                                        {el.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        ) : (
                                                            <Field
                                                                fullWidth
                                                                variant="standard"
                                                                name="birth_place.region"
                                                                component={TextField}
                                                                label={t("Region")}
                                                            />
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <Field
                                                            fullWidth
                                                            variant="standard"
                                                            name="birth_place.city"
                                                            component={TextField}
                                                            label={t("City / Village")}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={4}>
                                                        <Field
                                                            fullWidth
                                                            variant="standard"
                                                            name="birth_place.address1"
                                                            component={TextField}
                                                            label={t("Address 1")} 
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <Field
                                                            fullWidth
                                                            variant="standard"
                                                            name="birth_place.address2"
                                                            component={TextField}
                                                            label={t("Address 2")}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={4}>
                                                        <Field fullWidth variant="standard" name="birth_place.zip_code" component={TextField} label={t("Zip Code")} />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <p className="text-center mt-3">{t("Addresses")}</p>
                                                    </Grid>

                                                    <FieldArray name="addresses" className="w-75">
                                                        {({ push, remove }) => (
                                                            <>
                                                                {values.addresses.map((_, index) => (
                                                                    <Grid container item key={index} spacing={2}>
                                                                        <Grid item container spacing={2} xs={12}>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    variant="standard"
                                                                                    select
                                                                                    component={TextField}
                                                                                    onClick={() => (_.region = "")}
                                                                                    name={`addresses[${index}].country`}
                                                                                    label={t("Select Country")}
                                                                                >
                                                                                    {country.map((el) => (
                                                                                        <MenuItem key={el.id} value={el.name}>
                                                                                            {el.name}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Field>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                {_.country === "Uzbekistan" ? (
                                                                                    <Field
                                                                                        fullWidth
                                                                                        select
                                                                                        variant="standard"
                                                                                        component={TextField}
                                                                                        name={`addresses[${index}].region`}
                                                                                        label={t("Select Region")}
                                                                                    >
                                                                                        {regions.map((el) => (
                                                                                            <MenuItem key={el.id} value={el.name}>
                                                                                                {el.name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Field>
                                                                                ) : (
                                                                                    <Field
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        name={`addresses[${index}].region`}
                                                                                        component={TextField}
                                                                                        label={t("Region")}
                                                                                    />
                                                                                )}
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    variant="standard"
                                                                                    name={`addresses[${index}].city`}
                                                                                    component={TextField}
                                                                                    label={t("City / Village")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    variant="standard"
                                                                                    name={`addresses[${index}].address1`}
                                                                                    component={TextField}
                                                                                    label={t("Address 1")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    variant="standard"
                                                                                    name={`addresses[${index}].address2`}
                                                                                    component={TextField}
                                                                                    label={t("Address 2")}
                                                                                />
                                                                            </Grid>

                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    variant="standard"
                                                                                    name={`addresses[${index}].zip_code`}
                                                                                    component={TextField}
                                                                                    label={t("Zip Code")}
                                                                                />
                                                                            </Grid>

                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    variant="standard"
                                                                                    component={TextField}
                                                                                    select
                                                                                    name={`addresses[${index}].current`}
                                                                                    label={t("Current")}
                                                                                >
                                                                                    <MenuItem value="true">{t("true")}</MenuItem>
                                                                                    <MenuItem value="false">{t("false")}</MenuItem>
                                                                                </Field>
                                                                            </Grid>

                                                                            <Grid item xs={12} sm={4}>
                                                                                <label className="mat_label">{t("From date")}</label>
                                                                                <Field
                                                                                    fullWidth
                                                                                    variant="standard"
                                                                                    type="date"
                                                                                    format="dd/mm/yyyy"
                                                                                    name={`addresses[${index}].from_date`}
                                                                                    component={TextField}
                                                                                />
                                                                            </Grid>
                                                                            {(_.current === false || _.current === "false") && (
                                                                                <Grid item xs={12} sm={4}>
                                                                                    <label className="mat_label">{t("To date")}</label>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        type="date"
                                                                                        format="dd/mm/yyyy"
                                                                                        name={`addresses[${index}].to_date`}
                                                                                        component={TextField}
                                                                                    />
                                                                                </Grid>
                                                                            )}

                                                                            <Grid item xs={12}>
                                                                                <Button
                                                                                    color="error"
                                                                                    variant="contained"
                                                                                    className="float-end"
                                                                                    disabled={isSubmitting}
                                                                                    onClick={() => remove(index)}
                                                                                    style={index === 0 ? { display: "none" } : { display: "block" }}
                                                                                >
                                                                                    {t("Delete")}
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}

                                                                <Grid item>
                                                                    {typeof errors.addresses === "string" ? (
                                                                        <Typography color="error">{errors.addresses}</Typography>
                                                                    ) : null}
                                                                </Grid>

                                                                <Grid item xs={12}>
                                                                    <Button disabled={isSubmitting} variant="contained" onClick={() => push(emptyAddress)}>
                                                                        {t("Add")}
                                                                    </Button>
                                                                </Grid>
                                                            </>
                                                        )}
                                                    </FieldArray>

                                                    <Grid item xs={12}>
                                                        <p className="text-center">{t("Dependencies")}</p>
                                                    </Grid>

                                                    <FieldArray name="dependency">
                                                        {({ push, remove }) => (
                                                            <React.Fragment>
                                                                {values.dependency.map((_, index) => (
                                                                    <Grid container item key={index} spacing={2}>
                                                                        <Grid item container spacing={2} xs={12}>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    variant="standard"
                                                                                    name={`dependency[${index}].first_name`}
                                                                                    component={TextField}
                                                                                    label={t("First Name")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    variant="standard"
                                                                                    name={`dependency[${index}].last_name`}
                                                                                    component={TextField}
                                                                                    label={t("Last Name")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    variant="standard"
                                                                                    name={`dependency[${index}].middle_name`}
                                                                                    component={TextField}
                                                                                    label={t("Middle Name")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <label className="mat_label">{t("Birth Date")}</label>
                                                                                <Field
                                                                                    fullWidth
                                                                                    variant="standard"
                                                                                    type="date"
                                                                                    format="dd/mm/yyyy"
                                                                                    name={`dependency[${index}].birth_date`}
                                                                                    component={TextField}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    variant="standard"
                                                                                    name={`dependency[${index}].tin`}
                                                                                    component={TextField}
                                                                                    label={t("TIN")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    variant="standard"
                                                                                    select
                                                                                    component={TextField}
                                                                                    name={`dependency[${index}].type_of_relationship`}
                                                                                    label={t("Relationship")}
                                                                                >
                                                                                    {relationship.map((el) => (
                                                                                        <MenuItem key={el.id} value={el.id}>
                                                                                            {el.relationship}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Field>
                                                                            </Grid>

                                                                            <Grid item xs={12}>
                                                                                <Button
                                                                                    color="error"
                                                                                    variant="contained"
                                                                                    className="float-end"
                                                                                    disabled={isSubmitting}
                                                                                    onClick={() => remove(index)}
                                                                                    style={index === 0 ? { display: "none" } : { display: "block" }}
                                                                                >
                                                                                    {t("Delete")}
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}

                                                                <Grid item className="p-0 m-0">
                                                                    {typeof errors.dependency === "string" ? (
                                                                        <Typography color="error">{errors.dependency}</Typography>
                                                                    ) : null}
                                                                </Grid>

                                                                <Grid item xs={6}>
                                                                    <Button
                                                                        className="mt-2"
                                                                        disabled={isSubmitting}
                                                                        variant="contained"
                                                                        onClick={() => push(emptyDependency)}
                                                                    >
                                                                        {t("Add")}
                                                                    </Button>
                                                                </Grid>
                                                            </React.Fragment>
                                                        )}
                                                    </FieldArray>

                                                    <Grid item xs={6} sm={6}>
                                                        <Button
                                                            className="float-end mt-2"
                                                            disabled={isSubmitting}
                                                            type="submit"
                                                            variant="contained"
                                                            startIcon={isSubmitting ? <CircularProgress size="0.9rem" /> : undefined}
                                                        >
                                                            {isSubmitting ? `${t("Saving")}` : `${t("Save")}`}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    )}
                                </Formik>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
}
