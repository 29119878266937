// ---- ---- PERSONAL ---- ---- ---- //

export const emptyAddress = {
    country: "",
    address1: "",
    address2: undefined,
    city: "",
    region: "",
    zip_code: undefined,
    current: "",
    from_date: "",
    to_date: "",
};

export const emptyDependency = {
    first_name: "",
    last_name: "",
    middle_name: undefined,
    birth_date: "",
    tin: undefined,
    type_of_relationship: "",
};

export const emptyPersonal = {
    first_name: "",
    last_name: "",
    middle_name: undefined,
    birth_date: "",
    tin: undefined,
    gender: "",
    nationality: undefined,
    citizenship: "",
    home_phone: undefined,
    cell_phone: "",
    birth_place: {
        country: "",
        address1: "",
        address2: undefined,
        city: "",
        region: "",
        zip_code: undefined,
    },
    addresses: [emptyAddress],
    dependency: [emptyDependency],
};

// ---- ---- ---- WORK HISTORY ---- ---- ---- //

export const emptyWorkHistory = {
    name: "",
    position: "",
    start_date: "",
    end_date: "",
    phone: "",
    email: undefined,
    website: undefined,
    supervisor: { name: "", position: "", phone: "", email: "" },
    references: [
        { first_name: "", last_name: "", position: "", phone: "", email: "" },
        { first_name: "", last_name: "", position: "", phone: "", email: "" },
    ],
    address: {
        country: "",
        address1: "",
        address2: undefined,
        city: "",
        region: "",
        zip_code: undefined,
    },
};

// ---- ---- ---- EDUCATION ---- ---- ---- //

export const emptyEducation = {
    university_id: "",
    major: "",
    is_complete: false,
    years_of_edu: "",
    degree_type: "",
    start_date: "",
    end_date: undefined,
    diploma: "",
};

// ---- ---- ---- CONTACT ---- ---- ---- //

export const emptyContact = {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    type_of_relationship: "",
};
