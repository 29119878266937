/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, CircularProgress, Grid, MenuItem, Tooltip, Typography } from "@mui/material";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { toast } from "react-toastify";
import { workHistorySchema } from "../validateSchema";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import REACT_APP from "../../environment";
import { useTranslation } from 'react-i18next';
export default function ReviewWorkForm({ send, set }) {
    let navigate = useNavigate();
    const [work, setWork] = useState([]);
    const [country, setCountry] = useState([]);
    const [regions, setRegions] = useState([]);
    const [disable, setDisable] = useState(true);
    const [loading, setLoading] = useState(true);
    const [backLoading, setBackLoading] = useState(false);

    useEffect(() => {
        getWork();
        getCountry();
        getRegions();
    }, []);

    const getCountry = () => {
        axios(`${REACT_APP.COMMON_API}/tts/api/v01/countries`, {
            headers: { "client-id": REACT_APP.COMMON_ID, secret: REACT_APP.COMMON_SECRET },
        }).then((res) => setCountry(res.data.data));
    };

    const getRegions = () => {
        axios(`${REACT_APP.COMMON_API}/tts/api/v01/region/14ed6cfc-d787-45ba-b44d-84ac06e37935`, {
            headers: { "client-id": REACT_APP.COMMON_ID, secret: REACT_APP.COMMON_SECRET },
        }).then((res) => setRegions(res.data.data));
    };

    const getWork = async () => {
        setLoading(true);
        await fetch(`${REACT_APP.API_URL}/api/hrp/v2/onb/workhistory`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((response) => response.json())
            .then((res) => {
                setWork(res.data);
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const submit = async (values) => {
        setDisable(true);
        await values.work?.filter((el) => {
            if (!el.email) el.email = undefined;
            if (!el.website) el.website = undefined;
            if (!el.address.zip_code) el.address.zip_code = undefined;
            if (!el.address.address2) el.address.address2 = undefined;
        });

        axios
            .put(`${REACT_APP.API_URL}/api/hrp/v2/onb/workhistory`, values.work, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setDisable(true);
                set((send) => ({ ...send, work: null }));
                getWork();
            })
            .catch((err) => {
                setDisable(false);
                toast.error("An error occurred!");
            });

        console.log(values);
    };

    const handleEdit = () => {
        setDisable(false);
        set((send) => ({ ...send, work: "save" }));
    };

    const handleBack = () => {
        setDisable(true);
        setBackLoading(true);
        axios
            .put(
                `${REACT_APP.API_URL}/api/hrp/v2/onb/check`,
                {
                    work_history: 0,
                },
                { headers: { Authorization: sessionStorage.getItem("token") } }
            )
            .then((res) => {
                setDisable(false);
                setBackLoading(false);
                navigate("/");
            })
            .catch((err) => toast.error("An error occurred while back to work history page!"));
    };
    const{t}=useTranslation()

    return (
        <div className="d-flex justify-content-center flex-column container py-3">
            <div className="py-2">
                <div className="card">
                    <div className="card-header">
                        <h5 className="text-center" style={disable ? { color: "#aaa" } : {}}>
                            {t("Work History")}
                        </h5>
                    </div>
                    <div>
                        {loading ? (
                            <div className="w-100 d-flex justify-content-center align-items-center py-5 my-5">
                                <CircularProgress />
                            </div>
                        ) : work.length !== 0 ? (
                            <Card>
                                <CardContent style={{ padding: "24px" }}>
                                    <Formik
                                        initialValues={{ work: work }}
                                        validationSchema={workHistorySchema}
                                        onSubmit={async (values) => {
                                            submit(values);
                                            return new Promise((res) => setTimeout(res, 500));
                                        }}
                                    >
                                        {({ values, errors, isSubmitting }) => (
                                            <Form autoComplete="off">
                                                <Grid container direction="column" spacing={2}>
                                                    <FieldArray name="work">
                                                        {({ push, remove }) => (
                                                            <React.Fragment>
                                                                {values.work.map((_, index) => (
                                                                    <Grid container item key={index} spacing={2}>
                                                                        <Grid item container spacing={2} xs={12}>
                                                                            <Grid item xs={12} style={index === 0 ? { display: "none" } : { display: "block" }}>
                                                                                <p
                                                                                    className="text-center mt-3"
                                                                                    style={
                                                                                        disable ? { fontWeight: "500", color: "#aaa" } : { fontWeight: "500" }
                                                                                    }
                                                                                >
                                                                                    {t("Work history")} {index + 1}
                                                                                </p>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`work[${index}].name`}
                                                                                    pattern={"[0-9]"}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Name")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`work[${index}].position`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Position")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`work[${index}].phone`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Company phone")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`work[${index}].website`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Company website")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`work[${index}].email`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Company email")}
                                                                                />
                                                                            </Grid>

                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`work[${index}].supervisor.name`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Supervisor name")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`work[${index}].supervisor.position`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Supervisor position")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`work[${index}].supervisor.phone`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Supervisor phone")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`work[${index}].supervisor.email`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Supervisor email")}
                                                                                />
                                                                            </Grid>

                                                                            <Grid item xs={12} sm={6}>
                                                                                <label className="mat_label">{t("Date of starting")}</label>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    type="date"
                                                                                    name={`work[${index}].start_date`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    format="dd/MM/yyyy"
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={6}>
                                                                                <label className="mat_label">{t("Date of leaving")}</label>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    type="date"
                                                                                    name={`work[${index}].end_date`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    format="dd/MM/yyyy"
                                                                                />
                                                                            </Grid>

                                                                            <Grid item xs={12}>
                                                                                <p className="text-center mt-3" style={disable ? { color: "#aaa" } : {}}>
                                                                                    {t("References")}
                                                                                </p>
                                                                            </Grid>

                                                                            {_.references?.map((el, idx) => (
                                                                                <Grid container item key={idx} spacing={2}>
                                                                                    <Grid item container spacing={2} xs={12}>
                                                                                        <Grid item xs={12} sm={6}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`work[${index}].references[${idx}].first_name`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                label={t("First Name")}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={6}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`work[${index}].references[${idx}].last_name`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                label={t("Last Name")}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`work[${index}].references[${idx}].position`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                label={t("Position")}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`work[${index}].references[${idx}].phone`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                label={t("Phone")}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`work[${index}].references[${idx}].email`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                label={t("Email")}
                                                                                            />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            ))}

                                                                            <Grid item xs={12}>
                                                                                <p className="text-center mt-3" style={disable ? { color: "#aaa" } : {}}>
                                                                                    {t("Address")}
                                                                                </p>
                                                                            </Grid>

                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`work[${index}].address.address1`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Address 1")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`work[${index}].address.address2`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Address 2")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`work[${index}].address.city`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("City")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`work[${index}].address.zip_code`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Zip Code")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    name={`work[${index}].address.country`}
                                                                                    onClick={() => (_.address.region = "")}
                                                                                    select
                                                                                    label={t("Country")}
                                                                                >
                                                                                    {country.map((el) => (
                                                                                        <MenuItem key={el.id} value={el.name}>
                                                                                            {el.name}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Field>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                {_.address.country === "Uzbekistan" ? (
                                                                                    <Field
                                                                                        fullWidth
                                                                                        disabled={disable}
                                                                                        name={`work[${index}].address.region`}
                                                                                        component={TextField}
                                                                                        select
                                                                                        variant="standard"
                                                                                        label={t("Select Region")}
                                                                                    >
                                                                                        {regions.map((el) => (
                                                                                            <MenuItem key={el.id} value={el.name}>
                                                                                                {el.name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Field>
                                                                                ) : (
                                                                                    <Field
                                                                                        fullWidth
                                                                                        label={t("Region")}
                                                                                        name={`work[${index}].address.region`}
                                                                                        component={TextField}
                                                                                        variant="standard"
                                                                                        disabled={disable}
                                                                                    />
                                                                                )}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}

                                                                <Grid item>
                                                                    {typeof errors.work === "string" ? (
                                                                        <Typography color="error">{errors.work}</Typography>
                                                                    ) : null}
                                                                </Grid>

                                                                <Grid item xs={12}>
                                                                    {send.work === "disabled" && (
                                                                        <Button className="float-end" variant="contained" onClick={() => handleEdit()}>
                                                                            {disable ? t("Edit") : t("Disabled")}
                                                                        </Button>
                                                                    )}
                                                                    {send.work === "save" && (
                                                                        <>
                                                                            <Tooltip title="Back to Work history page and add new field" arrow>
                                                                                <Button
                                                                                    startIcon={
                                                                                        backLoading ? <CircularProgress size="0.9rem" /> : <ArrowBackIcon />
                                                                                    }
                                                                                    disabled={disable}
                                                                                    variant="outlined"
                                                                                    color="secondary"
                                                                                    onClick={() => handleBack()}
                                                                                >
                                                                                    {t("Back")}
                                                                                </Button>
                                                                            </Tooltip>
                                                                            <Button
                                                                                className="float-end"
                                                                                disabled={isSubmitting || disable}
                                                                                variant="contained"
                                                                                type="submit"
                                                                                startIcon={
                                                                                    isSubmitting || disable ? <CircularProgress size="0.9rem" /> : undefined
                                                                                }
                                                                            >
                                                                                {isSubmitting || disable ? t("Saving") : t("Save")}
                                                                            </Button>
                                                                        </>
                                                                    )}
                                                                </Grid>
                                                            </React.Fragment>
                                                        )}
                                                    </FieldArray>
                                                </Grid>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardContent>
                            </Card>
                        ) : (
                            <div className="w-100 text-center py-5 my-5">
                                <p style={{ color: "#1976d2", marginBottom: "20px" }}>{t("Work history is Empty")}</p>
                                <Tooltip title="Back to Work history page and add new field" arrow>
                                    <Button
                                        startIcon={backLoading ? <CircularProgress size="0.9rem" /> : <ArrowBackIcon />}
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => handleBack()}
                                    >
                                        {t("Back")}
                                    </Button>
                                </Tooltip>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
