const Loader = () => {
    return (
        <div className="container d-flex align-items-center justify-content-center" style={{ height: "80vh" }}>
            <h1 id="h1">
                <span id="load" className="let1">
                    l
                </span>
                <span id="load" className="let2">
                    o
                </span>
                <span id="load" className="let3">
                    a
                </span>
                <span id="load" className="let4">
                    d
                </span>
                <span id="load" className="let5">
                    i
                </span>
                <span id="load" className="let6">
                    n
                </span>
                <span id="load" className="let7">
                    g
                </span>
            </h1>
        </div>
    );
};
export default Loader;
