/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import Login from "./components/Login/Login";
import SignUp from "./components/Login/SignUp";
import Header from "./header/Header";

function App() {
    const lan=localStorage.getItem("lan")
    if(!lan)localStorage.setItem("lan","en")
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="*" element={<Header />}></Route>
                    <Route path="/login" element={<Login />}></Route>
                    <Route path="/register" element={<SignUp />}></Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
