/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, Checkbox, CircularProgress, FormControlLabel, Grid, MenuItem, Typography } from "@mui/material";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import Loader from "../Loader";
import { responseApi } from "../CheckApi";
import { contactSchema } from "../validateSchema";
import { emptyContact } from "../emptyValues";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import REACT_APP from "../../environment";

export default function Contacts() {
    let navigate = useNavigate();
    const [next, setNext] = useState(false);
    const [loader, setLoader] = useState(true);
    const [loading, setLoading] = useState(false);
    const [contact, setContact] = useState([]);
    const [nextLoading, setNextLoading] = useState(false);
    const [relationship, setRelationship] = useState([]);

    useEffect(() => {
        responseApi(navigate);
        getContact();
        getRelationType();
    }, []);

    const getRelationType = () => {
        axios(`${REACT_APP.API_URL}/api/hrp/v2/onb/contact/types`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        }).then((res) => setRelationship(res.data.data));
    };

    const getContact = () => {
        setLoader(true);
        axios
            .get(`${REACT_APP.API_URL}/api/hrp/v2/onb/emergency_contacts`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setContact(res.data.data);
                setLoader(false);
            })
            .catch((err) => {
                console.log(err);
                setLoader(false);
                toast.error("An error occurred while getting contacts!");
            });
    };

    const submit = (value) => {
        setLoading(true);
        const result = value.contacts.filter((el) => !el.id);
        if (result.length === 0) {
            handleNext();
            return;
        }

        axios
            .post(`${REACT_APP.API_URL}/api/hrp/v2/onb/emergency_contacts`, result, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                navigate("/skill");
                setLoading(false);
            })
            .catch((err) => {
                toast.error("An error occurred while saving contacts!");
                setLoading(false);
            });
    };

    const handleNext = () => {
        setNextLoading(true);
        axios
            .put(
                `${REACT_APP.API_URL}/api/hrp/v2/onb/check`,
                {
                    contact: 1,
                },
                { headers: { Authorization: sessionStorage.getItem("token") } }
            )
            .then((res) => {
                setNextLoading(false);
                navigate("/");
            })
            .catch((err) => toast.error("An error occurred while go to next page!"));
    };
    const{t}=useTranslation()

    return loader ? (
        <Loader />
    ) : (
        <>
            {contact.length === 0 && (
                <div disabled={next} className="d-flex justify-content-center container pt-4">
                    <div className="col-sm-6">
                        <FormControlLabel
                            control={<Checkbox disabled={nextLoading} onClick={() => setNext((prev) => !prev)} />}
                            label={t("If you don't have Contact! Click here")}
                        />
                    </div>
                    <div className="col-sm-6">
                        <Button
                            onClick={() => handleNext()}
                            className="float-end"
                            disabled={!next || nextLoading}
                            variant="contained"
                            startIcon={nextLoading && <CircularProgress size="0.9rem" />}
                        >
                            {t("Next")}
                        </Button>
                    </div>
                </div>
            )}
            {!next && (
                <div className="d-flex justify-content-center flex-column container py-3">
                    <ToastContainer />
                    {loading ? <div className="hrp-overlay"></div> : null}
                    <div className="py-2">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="text-center">{t("Contacts")}</h5>
                            </div>
                            <div>
                                <Card>
                                    <CardContent style={{ padding: "20px" }}>
                                        <Formik
                                            initialValues={contact.length !== 0 ? { contacts: contact } : { contacts: [emptyContact] }}
                                            validationSchema={contactSchema}
                                            onSubmit={async (values) => {
                                                submit(values);
                                                return new Promise((res) => setTimeout(res, 100));
                                            }}
                                        >
                                            {({ values, errors, isSubmitting }) => (
                                                <Form autoComplete="off">
                                                    <Grid container direction="column" spacing={2}>
                                                        <FieldArray name="contacts">
                                                            {({ push, remove }) => (
                                                                <React.Fragment>
                                                                    {values.contacts.map((_, index) => (
                                                                        <Grid container item key={index} spacing={2}>
                                                                            <Grid item container spacing={2} xs={12}>
                                                                                <Grid
                                                                                    item
                                                                                    xs={12}
                                                                                    style={index === 0 ? { display: "none" } : { display: "block" }}
                                                                                >
                                                                                    <p className="text-center fw-bold">{t("Contact")} {index + 1}</p>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={4}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        name={`contacts[${index}].first_name`}
                                                                                        pattern={"[0-9]"}
                                                                                        component={TextField}
                                                                                        disabled={_.id}
                                                                                        label={t("First Name")}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={4}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        name={`contacts[${index}].last_name`}
                                                                                        component={TextField}
                                                                                        disabled={_.id}
                                                                                        label={t("Last Name")}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={4}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        name={`contacts[${index}].email`}
                                                                                        component={TextField}
                                                                                        disabled={_.id}
                                                                                        label={t("Email")}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={6}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        name={`contacts[${index}].phone`}
                                                                                        component={TextField}
                                                                                        disabled={_.id}
                                                                                        label={t("Phone")}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={6}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        select
                                                                                        component={TextField}
                                                                                        disabled={_.id}
                                                                                        name={`contacts[${index}].type_of_relationship`}
                                                                                        label={t("Relationship")}
                                                                                    >
                                                                                        {relationship.map((el) => (
                                                                                            <MenuItem key={el.id} value={el.id}>
                                                                                                {el.relationship}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Field>
                                                                                </Grid>

                                                                                <Grid item xs={12} sm={12}>
                                                                                    <Button
                                                                                        style={
                                                                                            index === 0 || _?.id ? { display: "none" } : { display: "block" }
                                                                                        }
                                                                                        color="error"
                                                                                        disabled={isSubmitting}
                                                                                        variant="contained"
                                                                                        onClick={() => remove(index)}
                                                                                    >
                                                                                        {t("Delete")}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    ))}

                                                                    <Grid item>
                                                                        {typeof errors.contacts === "string" ? (
                                                                            <Typography color="error">{errors.contacts}</Typography>
                                                                        ) : null}
                                                                    </Grid>

                                                                    <Grid item container spacing={2} xs={12}>
                                                                        <Grid item xs={6} sm={6}>
                                                                            <Button
                                                                                disabled={isSubmitting}
                                                                                variant="contained"
                                                                                onClick={() => push(emptyContact)}
                                                                            >
                                                                                {t("Add")}
                                                                            </Button>
                                                                        </Grid>

                                                                        <Grid item xs={6} sm={6}>
                                                                            <Button
                                                                                className="float-end"
                                                                                disabled={isSubmitting}
                                                                                type="submit"
                                                                                variant="contained"
                                                                                startIcon={isSubmitting && <CircularProgress size="0.9rem" />}
                                                                            >
                                                                                {isSubmitting ? t("Saving") : t("Save")}
                                                                            </Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                </React.Fragment>
                                                            )}
                                                        </FieldArray>
                                                    </Grid>
                                                </Form>
                                            )}
                                        </Formik>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
