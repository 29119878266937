/* eslint-disable no-useless-escape */
import regex from "tts-hrp-val-lib";
import { array, boolean, number, object, string } from "yup";
import * as Yup from "yup";
import i18next from "i18next";
import resource from ".//..//i18n/resources.json";
export const validateSignIn = Yup.object({
    email: Yup.string().email(i18next.t("Email is invalid")).required("Email is required"),
    password: Yup.string().min(6, "Password must be at least 6 charaters").required("Password is required"),
});
await resource
export const validateSignUp = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string().min(6, "Password must be at least 6 charaters").required("Password is required"),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Password must match")
        .required("Confirm password is required"),
});

// ---- ---- ---- PERSONAL ---- ---- ---- //

export const personalSchema = object({
    first_name: string().matches(regex.first_name, { message: "Invalid first name" }).required(String(i18next.t(`First name is required`))),
    last_name: string().matches(regex.last_name, { message: "Invalid last name" }).required(String(i18next.t("Last name is required"))),
    middle_name: string().matches(regex.middle_name, { message: "Invalid middle name" }).nullable(),
    birth_date: Yup.date().max(new Date(), "Future date not allowed").typeError("Invalid birth date").required(String(i18next.t("Birth Date is required"))),
    tin: string().matches(regex.TIN, { message: "Invalid TIN number" }).nullable(),

    gender: string().required(String(i18next.t("Gender is required"))),
    nationality: string().matches(regex.nationality, { message: "Invalid nationality" }).nullable(),
    citizenship: string().matches(regex.citizenship, { message: "Invalid citizenship" }).required(String(i18next.t("Citizenship is required"))),
    home_phone: string().matches(regex.phone, { message: "Invalid phone number" }).required(String(i18next.t("Home phone is required"))),
    cell_phone: string().matches(regex.phone, { message: "Invalid phone number" }).required(String(i18next.t("Cell phone is required"))),

    birth_place: object({
        country: string().required(String(i18next.t("Country is required"))),
        address1: string().matches(regex.address1, { message: "Invalid address 1" }).required(String(i18next.t("Address 1 is required"))),
        address2: string().matches(regex.address2, { message: "Invalid address 2" }).nullable(),
        city: string().matches(regex.city, { message: "Invalid city" }).required(String(i18next.t("City is required"))),
        region: string().required(String(i18next.t("Region is required"))),
        zip_code: string().matches(regex.zip_code, { message: "Invalid ZipCode" }).nullable(),
    }),

    addresses: array(
        object({
            country: string().required(String(i18next.t("Country is required"))),
            address1: string().matches(regex.address1, { message: "Invalid address 1" }).required(String(i18next.t("Address 1 is required"))),
            address2: string().matches(regex.address2, { message: "Invalid address 2" }).nullable(),
            city: string().matches(regex.city, { message: "Invalid city" }).required(String(i18next.t("City is required"))),
            region: string().required(String(i18next.t("Region is required"))),
            zip_code: string().matches(regex.zip_code, { message: "Invalid ZipCode" }).nullable(),
            current: string().required(String(i18next.t("Current is required"))),
            from_date: Yup.date().max(new Date(), "Future date not allowed").typeError("Invalid from date").required(String(i18next.t("From date is required"))),
            to_date: Yup.date()
                .when("current", (current, field) => (current === "false" || current === false ? field.required(String(i18next.t("To date is required"))) : field))
                .when("from_date", (from_date, yup) => from_date && yup.min(from_date, "End date cannot be before start date")),
        })
    )
        .min(1, "You need to provide at least 1 address")
        .max(3, "You can only provide 3 address"),

    dependency: array(
        object({
            first_name: string().matches(regex.first_name, { message: "Invalid first name" }).required(String(i18next.t("First name is required"))),
            last_name: string().matches(regex.last_name, { message: "Invalid last name" }).required(String(i18next.t("Last name is required"))),
            middle_name: string().matches(regex.middle_name, { message: "Invalid middle name" }).nullable(),
            birth_date: Yup.date().max(new Date(), "Future date not allowed").typeError("Invalid birth date").required(String(i18next.t("Birth Date is required"))),
            tin: string().matches(regex.TIN, { message: "Invalid TIN number" }).nullable(),
            type_of_relationship: string().required(String(i18next.t("Relationship is required"))),
        })
    )
        .min(1, "You need to provide at least 1 dependency")
        .max(5, "You can only provide 5 dependency"),
});

// ---- ---- ---- WORK HISTORY ---- ---- ---- //

export const workHistorySchema = object({
    work: array(
        object({
            name: string().matches(regex.company_name, { message: "Invalid name" }).required(String(i18next.t("Name is required"))),
            position: string().matches(regex.work_position, { message: "Invalid position" }).required(String(i18next.t("Position is required"))),
            start_date: Yup.date().max(new Date(), "Future date not allowed").typeError("Invalid start date").required(String(i18next.t("Start date is required"))),
            end_date: Yup.date()
                .required(String(i18next.t("End date is required")))
                .when("start_date", (start_date, yup) => start_date && yup.min(start_date, "End date cannot be before start date")),
            email: string().email("Invalid email").nullable(),
            phone: string().matches(regex.phone, { message: "Invalid phone number" }).required(String(i18next.t("Your phone is required"))),
            website: string().matches(regex.website, { message: "Invalid website" }).nullable(),

            supervisor: object({
                name: string().matches(regex.full_name, { message: "Invalid name" }).required(String(i18next.t("Name is required"))),
                position: string().matches(regex.work_position, { message: "Invalid position" }).required(String(i18next.t("Position is required"))),
                phone: string().matches(regex.phone, { message: "Invalid phone number" }).required(String(i18next.t("Supervisor phone is required"))),
                email: string().required(String(i18next.t("Supervisor email is required"))).email("Invalid email"),
            }),

            references: array(
                object({
                    first_name: string().matches(regex.first_name, { message: "Invalid first name" }).required(String(i18next.t("First name is required"))),
                    last_name: string().matches(regex.last_name, { message: "Invalid last name" }).required(String(i18next.t("Last name is required"))),
                    position: string().matches(regex.work_position, { message: "Invalid position" }).required(String(i18next.t("Position is required"))),
                    phone: string().matches(regex.phone, { message: "Invalid phone number" }).required(String(i18next.t("Supervisor phone is required"))),
                    email: string().required(String(i18next.t("Supervisor email is required"))).email("Invalid email"),
                })
            ),

            address: object({
                country: string().required(String(i18next.t("Country is required"))),
                address1: string().matches(regex.address1, { message: "Invalid address 1" }).required(String(i18next.t("Address 1 is required"))),
                address2: string().matches(regex.address2, { message: "Invalid address 2" }).nullable(),
                city: string().matches(regex.city, { message: "Invalid city" }).required(String(i18next.t("City is required"))),
                region: string().required(String(i18next.t("Region is required"))),
                zip_code: string().matches(regex.zip_code, { message: "Invalid ZipCode" }).nullable(),
            }),
        })
    )
        .min(1, "You need to provide at least 1 Work history")
        .max(5, "You can only provide 5 Work history"),
});

// ---- ---- ---- EDUCATION ---- ---- ---- //

export const educationSchema = object({
    educations: array(
        object({
            university_id: string().required(String(i18next.t("Select University is required"))),
            major: string().matches(regex.major, { message: "Invalid major" }).required(String(i18next.t("Major is required"))),
            is_complete: boolean().required(),
            years_of_edu: number().required(String(i18next.t("Years of edu is required"))),
            degree_type: string().required(String(i18next.t("Degree type is required"))),
            start_date: Yup.date().max(new Date(), "Future date not allowed").typeError("Invalid enrollment date").required(String(i18next.t("Date of enrollment is required"))),
            diploma: string()
                .nullable()
                .when("is_complete", (is_complete, field) => (is_complete ? field.required("Diploma is required") : field)),
            end_date: Yup.date()
                .nullable()
                .when("is_complete", (is_complete, field) => (is_complete ? field.required(String(i18next.t("Date of graduation is required"))) : field))
                .when("start_date", (start_date, yup) => start_date && yup.min(start_date, "End date cannot be before enrollment date")),
        })
    )
        .min(1, "You need to provide at least 1 education")
        .max(5, "You can only provide 5 education"),
});

// ---- ---- ---- CONTACT ---- ---- ---- //

export const contactSchema = object({
    contacts: array(
        object({
            first_name: string().matches(regex.first_name, { message: "Invalid firs name" }).required(String(i18next.t("First name is required"))),
            last_name: string().matches(regex.last_name, { message: "Invalid firs name" }).required(String(i18next.t("Last name is required"))),
            email: string().required(String(i18next.t("Email is required"))).email("Invalid email"),
            phone: string().matches(regex.phone, { message: "Invalid phone number" }).required(String(i18next.t("Your phone is required"))),
            type_of_relationship: string().required(String(i18next.t("Relationship is required"))),
        })
    )
        .min(1, "You need to provide at least 1 contact")
        .max(5, "You can only provide 5 contacts"),
});
