/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, CircularProgress, FormControlLabel, FormLabel, Grid, MenuItem, Radio, Typography, Box } from "@mui/material";
import { Field, FieldArray, Form, Formik } from "formik";
import { RadioGroup, TextField } from "formik-material-ui";
import { toast } from "react-toastify";
import { personalSchema } from "../validateSchema";
import axios from "axios";
import REACT_APP from "../../environment";
import { useTranslation } from 'react-i18next';

export default function ReviewPersonalForm({ send, set }) {
    const [country, setCountry] = useState([]);
    const [regions, setRegions] = useState([]);
    const [disable, setDisable] = useState(true);
    const [loading, setLoading] = useState(true);
    const [personal, setPersonal] = useState([]);
    const [relationship, setRelationship] = useState([]);

    useEffect(() => {
        getPersonal();
        getRelationType();
        getCountry();
        getRegions();
    }, []);

    const getRelationType = () => {
        axios(`${REACT_APP.API_URL}/api/hrp/v2/onb/dependent/types`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        }).then((res) => setRelationship(res.data.data));
    };

    const getCountry = () => {
        axios(`${REACT_APP.COMMON_API}/tts/api/v01/countries`, {
            headers: { "client-id": REACT_APP.COMMON_ID, secret: REACT_APP.COMMON_SECRET },
        }).then((res) => setCountry(res.data.data));
    };

    const getRegions = () => {
        axios(`${REACT_APP.COMMON_API}/tts/api/v01/region/14ed6cfc-d787-45ba-b44d-84ac06e37935`, {
            headers: { "client-id": REACT_APP.COMMON_ID, secret: REACT_APP.COMMON_SECRET },
        }).then((res) => setRegions(res.data.data));
    };

    const getPersonal = async () => {
        setLoading(true);
        await fetch(`${REACT_APP.API_URL}/api/hrp/v2/onb/personal`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((response) => response.json())
            .then((res) => {
                setPersonal(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const submit = (values) => {
        setDisable(true);
        if (!values.middle_name) values.middle_name = undefined;
        if (!values.tin) values.tin = undefined;
        if (!values.nationality) values.nationality = undefined;
        if (!values.birth_place.address2) values.birth_place.address2 = undefined;
        if (!values.birth_place.zip_code) values.birth_place.zip_code = undefined;

        values.dependency.filter((el) => {
            if (!el.tin) el.tin = undefined;
            if (!el.middle_name) el.middle_name = undefined;
        });
        values.addresses.filter((el) => {
            console.log(el);
            if (!el.address2) el.address2 = undefined;
            if (!el.zip_code) el.zip_code = undefined;
            if (el.current === "true" || el.current === true) el.to_date = null;
        });

        axios
            .put(`${REACT_APP.API_URL}/api/hrp/v2/onb/personal`, values, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setDisable(true);
                getPersonal();
                set((send) => ({ ...send, per: null }));
            })
            .catch((err) => {
                setDisable(false);
                toast.error("An error occurred!");
            });
    };
    const{t}=useTranslation()

    return (
        <>
            {
                <div className="d-flex justify-content-center flex-column container py-3">
                    <div className="py-2">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="text-center" style={disable ? { color: "#aaa" } : {}}>
                                    {t("personal")}
                                </h5>
                            </div>
                            <div>
                                {loading ? (
                                    <div className="w-100 d-flex justify-content-center align-items-center py-5 my-5">
                                        <CircularProgress />
                                    </div>
                                ) : personal.length !== 0 ? (
                                    <Card>
                                        <CardContent style={{ padding: "24px" }}>
                                            <Formik
                                                initialValues={personal}
                                                validationSchema={personalSchema}
                                                onSubmit={async (values) => {
                                                    submit(values);
                                                    return new Promise((res) => setTimeout(res, 500));
                                                }}
                                            >
                                                {({ values, errors, isSubmitting }) => (
                                                    <Form autoComplete="off">
                                                        <Grid container direction="column" spacing={2}>
                                                            <Grid item container spacing={2} xs={12}>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        name="first_name"
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label={t("First Name")}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        name="last_name"
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label={t("Last Name")}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        name="middle_name"
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label={t("Middle Name")}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <label className="mat_label">{t("Birth Date")}</label>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        type="date"
                                                                        name="birth_date"
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        format="dd/mm/yyyy"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        name="tin"
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label={t("TIN")}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        name="nationality"
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label={t("Nationality")}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} sm={3}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        name="citizenship"
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label={t("Citizenship")}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={3}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        name="home_phone"
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label={t("Home phone")}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={3}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        name="cell_phone"
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label={t("Cell phone")}
                                                                    />
                                                                </Grid>
                                                                <Field component={RadioGroup} name="gender" className="ps-sm-3 col-sm-3 mt-2">
                                                                    <FormLabel component="legend">{t("Gender")}</FormLabel>
                                                                    <Grid>
                                                                        <FormControlLabel
                                                                            value="male"
                                                                            label={t("male")}
                                                                            control={<Radio disabled={isSubmitting} />}
                                                                            disabled={disable}
                                                                        />
                                                                        <FormControlLabel
                                                                            value="female"
                                                                            label={t("female")}
                                                                            control={<Radio disabled={isSubmitting} />}
                                                                            disabled={disable}
                                                                        />
                                                                    </Grid>
                                                                </Field>

                                                                <Grid item xs={12}>
                                                                    <p className="text-center mt-3" style={disable ? { color: "#aaa" } : {}}>
                                                                        {t("Birth place")}
                                                                    </p>
                                                                </Grid>

                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        component={TextField}
                                                                        select
                                                                        variant="standard"
                                                                        name="birth_place.country"
                                                                        onClick={() => (values.birth_place.region = "")}
                                                                        label={t("Select Country")}
                                                                    >
                                                                        {country.map((el) => (
                                                                            <MenuItem key={el.id} value={el.name}>
                                                                                {el.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Field>
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    {values.birth_place.country === "Uzbekistan" ? (
                                                                        <Field
                                                                            fullWidth
                                                                            disabled={disable}
                                                                            component={TextField}
                                                                            select
                                                                            name="birth_place.region"
                                                                            variant="standard"
                                                                            label={t("Select Region")}
                                                                        >
                                                                            {regions.map((el) => (
                                                                                <MenuItem key={el.id} value={el.name}>
                                                                                    {el.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Field>
                                                                    ) : (
                                                                        <Field
                                                                            fullWidth
                                                                            label={t("Region")}
                                                                            name="birth_place.region"
                                                                            component={TextField}
                                                                            variant="standard"
                                                                            disabled={disable}
                                                                        />
                                                                    )}
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        name="birth_place.city"
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label={t("City / Village")}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        name="birth_place.address1"
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label={t("Address 1")}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        name="birth_place.address2"
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label={t("Address 2")}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        name="birth_place.zip_code"
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label={t("Zip Code")}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12}>
                                                                    <p className="text-center mt-3" style={disable ? { color: "#aaa" } : {}}>
                                                                        {t("Addresses")}
                                                                    </p>
                                                                </Grid>

                                                                <FieldArray name="addresses">
                                                                    {({ push, remove }) => (
                                                                        <React.Fragment>
                                                                            {values.addresses?.map((address, index) => (
                                                                                <Grid container item key={index} spacing={2}>
                                                                                    <Grid item container spacing={2} xs={12}>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                onClick={() => (address.region = "")}
                                                                                                name={`addresses[${index}].country`}
                                                                                                component={TextField}
                                                                                                select
                                                                                                variant="standard"
                                                                                                label={t("Select Country")}
                                                                                            >
                                                                                                {country.map((el) => (
                                                                                                    <MenuItem key={el.id} value={el.name}>
                                                                                                        {el.name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                            </Field>
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            {address.country === "Uzbekistan" ? (
                                                                                                <Field
                                                                                                    fullWidth
                                                                                                    disabled={disable}
                                                                                                    component={TextField}
                                                                                                    variant="standard"
                                                                                                    select
                                                                                                    name={`addresses[${index}].region`}
                                                                                                    label={t("Select Region")}
                                                                                                >
                                                                                                    {regions.map((el) => (
                                                                                                        <MenuItem key={el.id} value={el.name}>
                                                                                                            {el.name}
                                                                                                        </MenuItem>
                                                                                                    ))}
                                                                                                </Field>
                                                                                            ) : (
                                                                                                <Field
                                                                                                    fullWidth
                                                                                                    label={t("Region")}
                                                                                                    name={`addresses[${index}].region`}
                                                                                                    component={TextField}
                                                                                                    disabled={disable}
                                                                                                    variant="standard"
                                                                                                />
                                                                                            )}
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`addresses[${index}].city`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                label={t("City / Village")}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`addresses[${index}].address1`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                label={t("Address 1")}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`addresses[${index}].address2`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                label={t("Address 2")}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`addresses[${index}].zip_code`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                label={t("Zip Code")}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <Field
                                                                                                select
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`addresses[${index}].current`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                label={t("Current")}
                                                                                            >
                                                                                                <MenuItem value="true">{t("true")}</MenuItem>
                                                                                                <MenuItem value="false">{t("false")}</MenuItem>
                                                                                            </Field>
                                                                                        </Grid>

                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <label className="mat_label">{t("From date")}</label>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                type="date"
                                                                                                name={`addresses[${index}].from_date`}
                                                                                                component={TextField}
                                                                                                format="dd/mm/yyyy"
                                                                                                variant="standard"
                                                                                            />
                                                                                        </Grid>
                                                                                        {(address.current === false || address.current === "false") && (
                                                                                            <Grid item xs={12} sm={4}>
                                                                                                <label className="mat_label">{t("To date")}</label>
                                                                                                <Field
                                                                                                    fullWidth
                                                                                                    disabled={disable}
                                                                                                    type="date"
                                                                                                    name={`addresses[${index}].to_date`}
                                                                                                    component={TextField}
                                                                                                    format="dd/mm/yyyy"
                                                                                                    variant="standard"
                                                                                                />
                                                                                            </Grid>
                                                                                        )}
                                                                                    </Grid>
                                                                                </Grid>
                                                                            ))}

                                                                            <Grid item>
                                                                                {typeof errors.addresses === "string" ? (
                                                                                    <Typography color="error">{errors.addresses}</Typography>
                                                                                ) : null}
                                                                            </Grid>
                                                                        </React.Fragment>
                                                                    )}
                                                                </FieldArray>

                                                                <Grid item xs={12}>
                                                                    <p className="text-center mt-3" style={disable ? { color: "#aaa" } : {}}>
                                                                        {t("Dependencies")}
                                                                    </p>
                                                                </Grid>

                                                                <FieldArray name="dependency">
                                                                    {({ push, remove }) => (
                                                                        <React.Fragment>
                                                                            {values.dependency?.map((_, index) => (
                                                                                <Grid container item key={index} spacing={2}>
                                                                                    <Grid item container spacing={2} xs={12}>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`dependency[${index}].first_name`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                label={t("First Name")}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`dependency[${index}].last_name`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                label={t("Last Name")}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`dependency[${index}].middle_name`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                label={t("Middle Name")}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <label className="mat_label">{t("Birth Date")}</label>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                type="date"
                                                                                                format="dd/mm/yyyy"
                                                                                                name={`dependency[${index}].birth_date`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`dependency[${index}].tin`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                label={t("TIN")}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`dependency[${index}].type_of_relationship`}
                                                                                                variant="standard"
                                                                                                select
                                                                                                component={TextField}
                                                                                                label={t("Relationship")}
                                                                                            >
                                                                                                {relationship.map((el) => (
                                                                                                    <MenuItem key={el.id} value={el.id}>
                                                                                                        {el.relationship}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                            </Field>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            ))}

                                                                            <Grid item className="p-0 m-0">
                                                                                {typeof errors.dependency === "string" ? (
                                                                                    <Typography color="error">{errors.dependency}</Typography>
                                                                                ) : null}
                                                                            </Grid>
                                                                        </React.Fragment>
                                                                    )}
                                                                </FieldArray>

                                                                <Grid item xs={12}>
                                                                    {send.per === "disabled" && (
                                                                        <Button
                                                                            className="float-end"
                                                                            variant="contained"
                                                                            onClick={() => {
                                                                                setDisable(false);
                                                                                set((send) => ({ ...send, per: "save" }));
                                                                            }}
                                                                        >
                                                                            {disable ? t("Edit") : t("Disabled")}
                                                                        </Button>
                                                                    )}
                                                                    {send.per === "save" && (
                                                                        <Button
                                                                            className="float-end"
                                                                            disabled={isSubmitting || disable}
                                                                            variant="contained"
                                                                            type="submit"
                                                                            startIcon={isSubmitting || disable ? <CircularProgress size="0.9rem" /> : undefined}
                                                                        >
                                                                            {isSubmitting || disable ? t("Saving") : t("Save")}
                                                                        </Button>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </CardContent>
                                    </Card>
                                ) : (
                                    <div className="w-100 d-flex justify-content-center align-items-center py-5 my-5">
                                        <p style={{ color: "#1976d2" }}>{t("Personal Field is Empty")}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
