import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import ReactDOM from "react-dom/client";
import React from "react";
import App from "./App";
import "w3-css/w3.css";
import "./index.css";

import "./i18n"
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <>
        <App />
    </>
);
