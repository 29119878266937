/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, CircularProgress, Grid, Typography } from "@mui/material";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { array, object, string } from "yup";
import Loader from "../Loader";
import { responseApi } from "../CheckApi";
import { useNavigate } from "react-router-dom";
import REACT_APP from "../../environment";
import regex from "tts-hrp-val-lib";
import { useTranslation } from 'react-i18next';

const emptyDocuments = { document_name: "", document: "" };

export default function Documents() {
    let navigate = useNavigate();
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        async function responseData() {
            setLoader(true);
            await responseApi(navigate);
            setLoader(false);
        }
        responseData();
    }, []);

    const submit = async (value) => {
        console.log(value);

        const resume = new FormData();
        resume.append("diploma", document.querySelector("#resume").files[0]);

        await fetch(`${REACT_APP.API_URL}/api/hrp/v2/onb/resume`, {
            method: "post",
            body: resume,
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => res.json())
            .then((date) => console.log(date));

        await value.documents.forEach((element, i) => {
            const body = new FormData();
            body.append("diploma", document.querySelector(`#documents${i}document`).files[0]);
            body.append("document_name", element.document_name);

            fetch(`${REACT_APP.API_URL}/api/hrp/v2/onb/document`, {
                method: "post",
                body: body,
                headers: { Authorization: sessionStorage.getItem("token") },
            })
                .then((res) => {
                    res.json();
                    if (res.ok) navigate("/review");
                })
                .then((date) => console.log(date));
        });
    };
    const{t}=useTranslation()

    return loader ? (
        <Loader />
    ) : (
        <div className="d-flex justify-content-center flex-column container py-3">
            <div className="py-2">
                <div className="card">
                    <div className="card-header">
                        <h5 className="text-center">{t("Resume")}</h5>
                    </div>
                    <div className="card-header position-absolute w-100" style={{ top: "174px" }}>
                        <h5 className="text-center">{t("Documents")}</h5>
                    </div>
                    <div>
                        <Card>
                            <CardContent style={{ padding: "24px" }}>
                                <Formik
                                    initialValues={{ resume: "", documents: [emptyDocuments] }}
                                    validationSchema={object({
                                        resume: string().required(t("Resume is required")),
                                        documents: array(
                                            object({
                                                document_name: string()
                                                    .matches(regex.file_name, { message: "Invalid name" })
                                                    .required(t("Document name is required")),
                                                document: string().required(t("Document is required")),
                                            })
                                        ),
                                    })}
                                    onSubmit={async (values) => {
                                        submit(values);
                                        return new Promise((res) => setTimeout(res, 500));
                                    }}
                                >
                                    {({ values, errors, isSubmitting, isValid }) => (
                                        <Form autoComplete="off">
                                            <Grid container direction="column" spacing={2}>
                                                <Grid item container spacing={2} xs={12}>
                                                    <Grid item xs={12} sm={6} className="m-auto">
                                                        <Field
                                                            fullWidth
                                                            name="resume"
                                                            style={{ marginBottom: "100px" }}
                                                            label={t("Resume")}
                                                            type="file"
                                                            component={TextField}
                                                            id="resume"
                                                            variant="standard"
                                                        />
                                                    </Grid>

                                                    {/* <Grid item xs={12}>
                            <div className="card-header mt-5 mb-2">
                              <h5 className="text-center">Documents</h5>
                            </div>
                          </Grid> */}

                                                    <FieldArray name="documents">
                                                        {({ push, remove }) => (
                                                            <React.Fragment>
                                                                {values.documents.map((_, index) => (
                                                                    <Grid container item key={index} spacing={2}>
                                                                        <Grid item container spacing={2} xs={12}>
                                                                            <Grid item xs={12} sm={6}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    variant="standard"
                                                                                    name={`documents[${index}].document_name`}
                                                                                    component={TextField}
                                                                                    label={t("Document Name")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={6}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    variant="standard"
                                                                                    type="file"
                                                                                    name={`documents[${index}].document`}
                                                                                    id={`documents${index}document`}
                                                                                    component={TextField}
                                                                                    label={t("Document")}
                                                                                />
                                                                            </Grid>

                                                                            <Grid item xs={6}>
                                                                                <Button
                                                                                    color="error"
                                                                                    variant="contained"
                                                                                    disabled={isSubmitting}
                                                                                    onClick={() => remove(index)}
                                                                                    style={index === 0 ? { display: "none" } : { display: "block" }}
                                                                                >
                                                                                    {t("Delete")}
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}

                                                                <Grid item className="p-0 m-0">
                                                                    {typeof errors.documents === "string" ? (
                                                                        <Typography color="error">{errors.documents}</Typography>
                                                                    ) : null}
                                                                </Grid>

                                                                <Grid item xs={6}>
                                                                    <Button
                                                                        className="mt-2"
                                                                        disabled={isSubmitting}
                                                                        variant="contained"
                                                                        onClick={() => push(emptyDocuments)}
                                                                    >
                                                                        {t("Add")}
                                                                    </Button>
                                                                </Grid>
                                                            </React.Fragment>
                                                        )}
                                                    </FieldArray>

                                                    <Grid item xs={6} sm={6}>
                                                        <Button
                                                            className="float-end mt-2"
                                                            disabled={isSubmitting}
                                                            type="submit"
                                                            variant="contained"
                                                            startIcon={isSubmitting ? <CircularProgress size="0.9rem" /> : undefined}
                                                        >
                                                            {isSubmitting ? t("Saving") : t("Save")}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    )}
                                </Formik>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
}
