/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Button, CircularProgress, Tooltip } from "@mui/material";
import FileSaver from "file-saver";
import axios from "axios";
import { toast } from "react-toastify";
import REACT_APP from "../../environment";
import { useTranslation } from 'react-i18next';

export default function ReviewResume({ send, set }) {
    const [resume, setResume] = useState([]);
    const [disable, setDisable] = useState(true);
    const [loading, setLoading] = useState(true);
    const [docType, setDocType] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);

    useEffect(() => {
        getResume();
    }, []);

    const getResume = () => {
        setLoading(true);
        axios
            .get(`${REACT_APP.API_URL}/api/hrp/v2/onb/resume`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setResume(res.data.data);
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const submit = () => {
        if (!document.querySelector("#resume").value) {
            setErrorMsg(true);
            return;
        }
        setLoadingModal(true);
        const body = new FormData();
        body.append("diploma", document.querySelector("#resume").files[0]);

        axios
            .post(`${REACT_APP.API_URL}/api/hrp/v2/onb/resume`, body, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setDisable(true);
                set((send) => ({ ...send, resume: null }));
                getResume();
                setLoadingModal(false);
            })
            .catch((err) => {
                toast.error("An error occurred!");
                setLoadingModal(false);
            });
    };

    const getImage = (id) => {
        setDocType(false);
        setLoadingModal(true);
        fetch(`${REACT_APP.HRP_DOC_API}/file/${id}`, {
            headers: {
                "client-id": REACT_APP.CLIENT_ID,
                secret: REACT_APP.SECRET,
                "Content-type": "application/pdf",
            },
        })
            .then((res) => res.blob())
            .then((blob) => {
                if (blob.type === "application/pdf" || /image/.test(blob.type)) {
                    setLoadingModal(false);
                    setTimeout(() => {
                        document.querySelector("#resume").src = window.URL.createObjectURL(blob);
                    });
                } else {
                    setLoadingModal(false);
                    setDocType(true);
                    setTimeout(() => {
                        FileSaver.saveAs(blob, "resume");
                    });
                }
            })
            .catch((err) => {
                setLoadingModal(false);
            });
    };
    const{t}=useTranslation()

    const removeFile = (id) => {
        console.log(id);
        axios
            .delete(`${REACT_APP.API_URL}/api/hrp/v2/onb/resume/${id}`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((date) => {
                getResume();
                set((send) => ({ ...send, resume: "save" }));
            })
            .catch((err) => toast.error("An error occurred!"));
    };

    const handleChange = (e) => {
        e.target.value ? setErrorMsg(false) : setErrorMsg(true);
    };

    return (
        <>
            <div className="d-flex justify-content-center flex-column container py-3">
                <div className="py-2">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="text-center" style={disable ? { color: "#aaa" } : {}}>
                                {t("Resume")}
                            </h5>
                        </div>
                        <div className="row d-flex justify-content-center p-5">
                            {loading ? (
                                <div className="w-100 d-flex justify-content-center align-items-center py-5 my-5">
                                    <CircularProgress />
                                </div>
                            ) : (
                                <>
                                    {resume?.length === 0 ? (
                                        <div className="row g-3 d-flex align-items-end justify-content-center">
                                            <div className="col-sm-5">
                                                <TextField
                                                    error={errorMsg}
                                                    style={{ width: "100%" }}
                                                    id="resume"
                                                    name="resume"
                                                    type="file"
                                                    variant="standard"
                                                    helperText={errorMsg && "Resume is required"}
                                                    onChange={handleChange}
                                                    disabled={loadingModal}
                                                />
                                            </div>
                                            <div className="col-sm-2">
                                                <Button
                                                    className="float-end mt-1"
                                                    onClick={() => submit()}
                                                    variant="contained"
                                                    disabled={loadingModal}
                                                    style={errorMsg ? { marginBottom: "20px" } : {}}
                                                    startIcon={loadingModal ? <CircularProgress size="0.9rem" /> : ""}
                                                >
                                                    {loadingModal ? t("Saving") : t("Save")}
                                                </Button>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="row g-3 d-flex align-items-end justify-content-center">
                                                <div className="col-md-6">
                                                    <div id="img_span">
                                                        <span
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#resumeModal"
                                                            onClick={() => getImage(resume[0]?.document_id)}
                                                        >
                                                            {resume[0]?.document_id}
                                                        </span>
                                                        {!disable && (
                                                            <Tooltip title="delete" arrow>
                                                                <button
                                                                    type="button"
                                                                    className="btn-close"
                                                                    aria-label="Close"
                                                                    onClick={() => removeFile(resume[0]?.document_id)}
                                                                ></button>
                                                            </Tooltip>
                                                        )}
                                                    </div>
                                                </div>
                                                {disable && (
                                                    <div className="col-md-2">
                                                        <Button
                                                            className="float-end mt-1"
                                                            variant="contained"
                                                            onClick={() => {
                                                                setDisable(false);
                                                            }}
                                                        >
                                                            Edit
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                    <div className="col-md-2 pt-2"></div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="resumeModal" aria-labelledby="resumeModalLabel" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content bg-white">
                        <div className="modal-header py-1">
                            <h5 className="modal-title" id="resumeModalLabel">
                                Resume
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body img-fluid">
                            {loadingModal ? (
                                <div className="spinner-border text-info my-5" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : docType ? (
                                <h2 className="m-auto my-5">File downloaded!</h2>
                            ) : (
                                <iframe id="resume" src="" alt="" height="750" width="900" />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
