/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, Checkbox, CircularProgress, FormControlLabel, Grid, MenuItem, Typography } from "@mui/material";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { responseApi } from "../CheckApi";
import Loader from "../Loader";
import REACT_APP from "../../environment";
import { useTranslation } from 'react-i18next';

import { workHistorySchema } from "../validateSchema";
import { emptyWorkHistory } from "../emptyValues";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

const WorkHistory = () => {
    let navigate = useNavigate();
    const [works, setWorks] = useState([]);
    const [next, setNext] = useState(false);
    const [country, setCountry] = useState([]);
    const [regions, setRegions] = useState([]);
    const [loader, setLoader] = useState(true);
    const [loading, setLoading] = useState(false);
    const [nextLoading, setNextLoading] = useState(false);

    useEffect(() => {
        responseApi(navigate);
        getWork();
        getCountry();
        getRegions();
    }, []);

    const getCountry = () => {
        axios(`${REACT_APP.COMMON_API}/tts/api/v01/countries`, {
            headers: { "client-id": REACT_APP.COMMON_ID, secret: REACT_APP.COMMON_SECRET },
        }).then((res) => setCountry(res.data.data));
    };

    const getRegions = () => {
        axios(`${REACT_APP.COMMON_API}/tts/api/v01/region/14ed6cfc-d787-45ba-b44d-84ac06e37935`, {
            headers: { "client-id": REACT_APP.COMMON_ID, secret: REACT_APP.COMMON_SECRET },
        }).then((res) => setRegions(res.data.data));
    };

    const getWork = async () => {
        setLoader(true);
        await fetch(`${REACT_APP.API_URL}/api/hrp/v2/onb/workhistory`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((response) => response.json())
            .then((res) => {
                if (res.data.length !== 0) {
                    console.log(res.data);
                    res.data.addresses?.forEach((el) => {
                        el.from_date = el.from_date.slice(0, 10);
                        el.to_date = el.to_date.slice(0, 10);
                    });
                }
                setWorks(res.data);
                setLoader(false);
            })
            .catch((err) => {
                console.log(err);
                setLoader(false);
                toast.error("An error occurred while getting Works!");
            });
    };

    const submit = (value) => {
        setLoader(true);
        const result = value.work.filter((el) => !el.id);
        if (result.length === 0) {
            handleNext();
            return;
        }

        result.filter((el) => {
            if (!el.email) el.email = undefined;
            if (!el.website) el.website = undefined;
            if (!el.address.zip_code) el.address.zip_code = undefined;
            if (!el.address.end_date) el.address.end_date = undefined;
            if (!el.address.address2) el.address.address2 = undefined;
        });

        axios
            .post(`${REACT_APP.API_URL}/api/hrp/v2/onb/workhistory`, result, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                navigate("/education");
                setLoader(false);
            })
            .catch((err) => {
                toast.error("An error occurred while saving work histories!");
                setLoader(false);
            });
    };

    const handleNext = () => {
        setNextLoading(true);
        axios
            .put(
                `${REACT_APP.API_URL}/api/hrp/v2/onb/check`,
                {
                    work_history: 1,
                },
                {
                    headers: { Authorization: sessionStorage.getItem("token") },
                }
            )
            .then((res) => {
                setNextLoading(false);
                navigate("/");
            })
            .catch((err) => toast.error("An error occurred while go to next page!"));
    };
    const{t}=useTranslation()
    return loader ? (
        <Loader />
    ) : (
        <>
            {works?.length === 0 && (
                <div disabled={next} className="d-flex justify-content-center container pt-4">
                    <div className="col-sm-6">
                        <FormControlLabel
                            control={<Checkbox disabled={nextLoading} onClick={() => setNext((prev) => !prev)} />}
                            label={t("If you don't have work experience Click here")}
                        />
                    </div>
                    <div className="col-sm-6">
                        <Button
                            onClick={() => handleNext()}
                            className="float-end"
                            disabled={!next || nextLoading}
                            variant="contained"
                            startIcon={nextLoading ? <CircularProgress size="0.9rem" /> : ""}
                        >
                            {t("Next")}
                        </Button>
                    </div>
                </div>
            )}
            {!next && (
                <div className="d-flex justify-content-center flex-column container py-3">
                    <ToastContainer autoClose={1500} />
                    {loading ? <div className="hrp-overlay"></div> : null}
                    <div className="py-2">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="text-center">{t("Work History")}</h5>
                            </div>
                            <div className="">
                                <Card>
                                    <CardContent style={{ padding: "20px" }}>
                                        <Formik
                                            initialValues={works?.length !== 0 ? { work: works } : { work: [emptyWorkHistory] }}
                                            validationSchema={workHistorySchema}
                                            onSubmit={async (values) => {
                                                submit(values);
                                                return new Promise((res) => setTimeout(res, 500));
                                            }}
                                        >
                                            {({ values, errors, isSubmitting }) => (
                                                <Form autoComplete="off">
                                                    <Grid container direction="column" spacing={2}>
                                                        <FieldArray name="work">
                                                            {({ push, remove }) => (
                                                                <React.Fragment>
                                                                    {values.work.map((_, index) => (
                                                                        <Grid container item key={index} spacing={2}>
                                                                            <Grid item container spacing={2} xs={12}>
                                                                                <Grid
                                                                                    item
                                                                                    xs={12}
                                                                                    style={index === 0 ? { display: "none" } : { display: "block" }}
                                                                                >
                                                                                    <p className="text-center fw-bold">{t("Work History")} {index + 1}</p>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={4}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        name={`work.${index}.name`}
                                                                                        pattern={"[0-9]"}
                                                                                        component={TextField}
                                                                                        disabled={_.id}
                                                                                        label={t("Name")}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={4}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        name={`work[${index}].position`}
                                                                                        component={TextField}
                                                                                        disabled={_.id}
                                                                                        label={t("Position")}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={4}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        name={`work[${index}].phone`}
                                                                                        component={TextField}
                                                                                        disabled={_.id}
                                                                                        label={t("Company phone")}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={4}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        name={`work[${index}].website`}
                                                                                        component={TextField}
                                                                                        disabled={_.id}
                                                                                        label={t("Company website")}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={4}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        name={`work.${index}.email`}
                                                                                        component={TextField}
                                                                                        disabled={_.id}
                                                                                        label={t("Company email")}
                                                                                    />
                                                                                </Grid>

                                                                                <Grid item xs={12} sm={4}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        name={`work.${index}.supervisor.name`}
                                                                                        component={TextField}
                                                                                        disabled={_.id}
                                                                                        label={t("Supervisor name")}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={4}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        name={`work[${index}].supervisor.position`}
                                                                                        component={TextField}
                                                                                        disabled={_.id}
                                                                                        label={t("Supervisor position")}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={4}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        name={`work[${index}].supervisor.phone`}
                                                                                        component={TextField}
                                                                                        disabled={_.id}
                                                                                        label={t("Supervisor phone")}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={4}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        name={`work.${index}.supervisor.email`}
                                                                                        component={TextField}
                                                                                        disabled={_.id}
                                                                                        label={t("Supervisor email")}
                                                                                    />
                                                                                </Grid>

                                                                                <Grid item xs={12} sm={6}>
                                                                                    <label className="mat_label">{t("Date of starting")}</label>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        type="date"
                                                                                        name={`work[${index}].start_date`}
                                                                                        component={TextField}
                                                                                        disabled={_.id}
                                                                                        format="dd/MM/yyyy"
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={6}>
                                                                                    <label className="mat_label">{t("Date of leaving")}</label>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        type="date"
                                                                                        name={`work[${index}].end_date`}
                                                                                        component={TextField}
                                                                                        disabled={_.id}
                                                                                        format="dd/MM/yyyy"
                                                                                    />
                                                                                </Grid>

                                                                                <Grid item xs={12}>
                                                                                    <p className="text-center mt-3">{t("References")}</p>
                                                                                </Grid>

                                                                                {_.references?.map((_, idx) => (
                                                                                    <Grid container item key={idx} spacing={2}>
                                                                                        <Grid item container spacing={2} xs={12}>
                                                                                            <Grid item xs={12} sm={6}>
                                                                                                <Field
                                                                                                    fullWidth
                                                                                                    variant="standard"
                                                                                                    name={`work[${index}].references[${idx}].first_name`}
                                                                                                    component={TextField}
                                                                                                    disabled={_.id}
                                                                                                    label={t("First Name")}
                                                                                                />
                                                                                            </Grid>
                                                                                            <Grid item xs={12} sm={6}>
                                                                                                <Field
                                                                                                    fullWidth
                                                                                                    variant="standard"
                                                                                                    name={`work[${index}].references[${idx}].last_name`}
                                                                                                    component={TextField}
                                                                                                    disabled={_.id}
                                                                                                    label={t("Last Name")}
                                                                                                />
                                                                                            </Grid>
                                                                                            <Grid item xs={12} sm={4}>
                                                                                                <Field
                                                                                                    fullWidth
                                                                                                    variant="standard"
                                                                                                    name={`work[${index}].references[${idx}].position`}
                                                                                                    component={TextField}
                                                                                                    disabled={_.id}
                                                                                                    label={t("Position")}
                                                                                                />
                                                                                            </Grid>
                                                                                            <Grid item xs={12} sm={4}>
                                                                                                <Field
                                                                                                    fullWidth
                                                                                                    variant="standard"
                                                                                                    name={`work[${index}].references[${idx}].phone`}
                                                                                                    component={TextField}
                                                                                                    disabled={_.id}
                                                                                                    label={t("Phone")}
                                                                                                />
                                                                                            </Grid>
                                                                                            <Grid item xs={12} sm={4}>
                                                                                                <Field
                                                                                                    fullWidth
                                                                                                    variant="standard"
                                                                                                    name={`work[${index}].references[${idx}].email`}
                                                                                                    component={TextField}
                                                                                                    disabled={_.id}
                                                                                                    label={t("Email")}
                                                                                                />
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                ))}

                                                                                <Grid item xs={12}>
                                                                                    <p className="text-center mt-3">{t("Address")}</p>
                                                                                </Grid>

                                                                                <Grid item xs={12} sm={4}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        name={`work.${index}.address.address1`}
                                                                                        component={TextField}
                                                                                        disabled={_.id}
                                                                                        label={t("Address 1")}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={4}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        name={`work.${index}.address.address2`}
                                                                                        component={TextField}
                                                                                        disabled={_.id}
                                                                                        label={t("Address 2")}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={4}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        name={`work.${index}.address.city`}
                                                                                        component={TextField}
                                                                                        disabled={_.id}
                                                                                        label={t("City")}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={4}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        name={`work.${index}.address.zip_code`}
                                                                                        component={TextField}
                                                                                        disabled={_.id}
                                                                                        label={t("Zip Code")}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={4}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        component={TextField}
                                                                                        disabled={_.id}
                                                                                        name={`work.${index}.address.country`}
                                                                                        onClick={() => (_.address.region = "")}
                                                                                        select
                                                                                        label={t("Country")}
                                                                                    >
                                                                                        {country.map((el) => (
                                                                                            <MenuItem key={el.id} value={el.name}>
                                                                                                {el.name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Field>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={4}>
                                                                                    {_.address.country === "Uzbekistan" ? (
                                                                                        <Field
                                                                                            fullWidth
                                                                                            variant="standard"
                                                                                            component={TextField}
                                                                                            disabled={_.id}
                                                                                            name={`work.${index}.address.region`}
                                                                                            select
                                                                                            label={t("Region")}
                                                                                        >
                                                                                            {regions.map((el) => (
                                                                                                <MenuItem key={el.id} value={el.name}>
                                                                                                    {el.name}
                                                                                                </MenuItem>
                                                                                            ))}
                                                                                        </Field>
                                                                                    ) : (
                                                                                        <Field
                                                                                            fullWidth
                                                                                            label={t("Region")}
                                                                                            name={`work.${index}.address.region`}
                                                                                            component={TextField}
                                                                                            variant="standard"
                                                                                        />
                                                                                    )}
                                                                                </Grid>

                                                                                <Grid item xs={12} sm={12}>
                                                                                    <Button
                                                                                        style={
                                                                                            index === 0 || _?.id ? { display: "none" } : { display: "block" }
                                                                                        }
                                                                                        color="error"
                                                                                        disabled={isSubmitting}
                                                                                        variant="contained"
                                                                                        onClick={() => remove(index)}
                                                                                    >
                                                                                        {t("Delete")}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    ))}

                                                                    <Grid item>
                                                                        {typeof errors.work === "string" ? (
                                                                            <Typography color="error">{errors.work}</Typography>
                                                                        ) : null}
                                                                    </Grid>

                                                                    <Grid item container spacing={2} xs={12}>
                                                                        <Grid item xs={6} sm={6}>
                                                                            <Button
                                                                                disabled={isSubmitting}
                                                                                variant="contained"
                                                                                onClick={() => push(emptyWorkHistory)}
                                                                            >
                                                                                {t("Add")}
                                                                            </Button>
                                                                        </Grid>

                                                                        <Grid item xs={6} sm={6}>
                                                                            <Button
                                                                                className="float-end"
                                                                                disabled={isSubmitting}
                                                                                type="submit"
                                                                                variant="contained"
                                                                                startIcon={isSubmitting ? <CircularProgress size="0.9rem" /> : undefined}
                                                                            >
                                                                                {isSubmitting ? t("Saving") : t("Save")}
                                                                            </Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                </React.Fragment>
                                                            )}
                                                        </FieldArray>
                                                    </Grid>
                                                </Form>
                                            )}
                                        </Formik>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default WorkHistory;
