import React, { useEffect, useState } from "react";
import REACT_APP from "../../environment";
import { useTranslation } from 'react-i18next';

export default function ReviewSkills() {
    const [getAll, setGetAll] = useState([]);
    const [expert, setExpert] = useState([]);
    const [beginner, setBeginner] = useState([]);
    const [advanced, setAdvanced] = useState([]);
    const [medium, setMedium] = useState([]);
    const [proficient, setProficient] = useState([]);

    useEffect(() => {
        fetch(`${REACT_APP.API_URL}/api/hrp/v2/onb/skill`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((response) => response.json())
            .then((res) => {
                setGetAll(res.data);
                res.data.forEach((el) => {
                    if (el.level === 1) setBeginner((prev) => [...prev, el]);
                    if (el.level === 2) setAdvanced((prev) => [...prev, el]);
                    if (el.level === 3) setMedium((prev) => [...prev, el]);
                    if (el.level === 4) setProficient((prev) => [...prev, el]);
                    if (el.level === 5) setExpert((prev) => [...prev, el]);
                });
            });
    }, []);
    const{t}=useTranslation()

    return (
        getAll.length !== 0 && (
            <div className="d-flex justify-content-center flex-column container my-2">
                <div id="revSkills" className="row g-3">
                    <div className="">
                        <div className="card form1">
                            <div className="card-header">
                                <h5 className="text-center" style={{ color: "#aaa" }}>
                                    {t("Skills")}
                                </h5>
                            </div>
                            <div className="card-body py-3 mb-4">
                                <div className="form-group row g-3">
                                    {beginner.length !== 0 && (
                                        <div className="col-md-6 my-4">
                                            <label className="form-label skill_lbl">{t("beginner")}</label>
                                            <div className="card-brd w-100 h-100 p-2">
                                                {beginner.map((el, i) => (
                                                    <span key={i} className="text-success m-1 rounded float-start d-flex align-items-center">
                                                        el.name
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {advanced.length !== 0 && (
                                        <div className="col-md-6 my-4">
                                            <label className="form-label skill_lbl">{t("advanced beginner")}</label>
                                            <div className="card-brd w-100 h-100 p-2">
                                                {advanced.map((el, i) => (
                                                    <span key={i} className="text-success m-1 rounded float-start d-flex align-items-center">
                                                        {el.name}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {medium.length !== 0 && (
                                        <div className="col-md-6 my-4">
                                            <label className="form-label skill_lbl">{t("medium")}</label>
                                            <div className="card-brd w-100 h-100 p-2">
                                                {medium.map((el, i) => (
                                                    <span key={i} className="text-success m-1 rounded float-start d-flex align-items-center">
                                                        {el.name}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {proficient.length !== 0 && (
                                        <div className="col-md-6 my-4">
                                            <label className="form-label skill_lbl">{t("proficient")}</label>
                                            <div className="card-brd w-100 h-100 p-2">
                                                {proficient.map((el, i) => (
                                                    <span key={i} className="text-success m-1 rounded float-start d-flex align-items-center">
                                                        {el.name}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {expert.length !== 0 && (
                                        <div className="col-md-6 my-4">
                                            <label className="form-label skill_lbl">{t("expert")}</label>
                                            <div className="card-brd w-100 h-100 p-2">
                                                {expert.map((el, i) => (
                                                    <span key={i} className="text-success m-1 rounded float-start d-flex align-items-center">
                                                        {el.name}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
}
