/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Grid, MenuItem } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { object, string } from "yup";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../Loader";
import { useNavigate } from "react-router-dom";
import { responseApi } from "../CheckApi";
import axios from "axios";
import REACT_APP from "../../environment";
import { useTranslation } from 'react-i18next';

import i18next from "i18next";
import resource from "./../../i18n/resources.json";
export default function Skills() {
    const a=resource
    let navigate = useNavigate();
    const [items, setItems] = useState([]);
    const [degree, setDegree] = useState([]);
    const [loader, setLoader] = useState(true);
    const [loading, setLoading] = useState(false);
    const query = document.querySelector.bind(document);

    const colorA = "rgba(139, 139, 139, 0.45) 0px 4px 4px -2px,rgba(101, 101, 101, 0.48) 0px 0px 1px 1px";
    const colorB = "rgba(255, 0, 0, 0.45) 0px 3px 3px -2px,rgba(255, 0, 0, 0.38) 0px 0px 1px 1px";

    useEffect(() => {
        if (items.length !== 0) {
            query("#lbl-list").style.display = "none";
            query(".card-brd").style.boxShadow = colorA;
        } else {
            if (query("#lbl-list")) query("#lbl-list").style.display = "block";
            if (query(".card-brd")) query(".card-brd").style.boxShadow = colorB;
        }
    }, [items]);

    useEffect(() => {
        async function responseData() {
            setLoader(true);
            await responseApi(navigate);
            await getDegree();
            setLoader(false);
        }
        responseData();

        if (query("#lbl-list")) query("#lbl-list").style.display = "none";
        if (query(".card-brd")) query(".card-brd").style.boxShadow = colorA;
    }, []);

    const getDegree = () => {
        axios(`${REACT_APP.API_URL}/api/hrp/v2/onb/skill/degree`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        }).then((res) => setDegree(res.data.data));
    };

    function addSkills(value) {
        if (value.errors) return;

        if (items.find((e) => e.name === value.name)) {
            toast.warning(i18next.t("Already exist!"));
            return;
        }
        setItems((prev) => [...prev, value]);
        toast.success(i18next.t("Successfully added!"));
    }

    const showBtn = (e) => {
        const atr = e.target.childNodes[1];
        if (atr) {
            atr.style.display === "block" ? (atr.style.display = "none") : (atr.style.display = "block");
        }
    };

    const removeItem = (e) => {
        const txt = e.target.parentElement.childNodes[0];
        setItems(items.filter((itm) => itm.name !== txt.textContent));
        txt.parentElement.childNodes[1].style.display = "none";
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (items.length === 0) {
            query("#lbl-list").style.display = "block";
            query(".card-brd").style.boxShadow = colorB;
            return;
        }
        setLoading(true);

        axios
            .post(`${REACT_APP.API_URL}/api/hrp/v2/onb/skill`, items, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                navigate("/document");
                setLoading(false);
            })
            .catch((err) => {
                toast.error("An error occurred while saving skills!");
                setLoading(false);
            });
    };
    const{t}=useTranslation()
    return loader ? (
        <Loader />
    ) : (
        <div className="d-flex justify-content-center flex-column container py-3">
            <ToastContainer autoClose={1200} />
            {loading ? <div className="hrp-overlay"></div> : null}
            <div id="skills" className="py-2">
                <div className="card form1">
                    <div className="card-header">
                        <h5 className="text-center">{t("Skills")}</h5>
                    </div>
                    <div className="card-body">
                        <div className="form-group row pb-3 my-1 d-flex justify-content-center">
                            <Formik
                                initialValues={{ name: "", level: "" }}
                                validationSchema={object({
                                    name: string().required(t("Skill is required")),
                                    level: string().required(t("Level is required")),
                                })}
                                onSubmit={async (values, { resetForm }) => {
                                    addSkills(values);
                                    resetForm({ values: "" });
                                }}
                            >
                                {({ values, errors, isValid }) => (
                                    <Form autoComplete="off" className=" d-flex justify-content-center">
                                        <Grid container direction="column">
                                            <Grid item container spacing={2} xs={12} className="d-flex justify-content-center">
                                                <Grid item xs={12} sm={5}>
                                                    <Field fullWidth variant="standard" name="name" component={TextField} label={t("Skill")} />
                                                </Grid>

                                                <Grid item xs={12} sm={5}>
                                                    <Field fullWidth variant="standard" component={TextField} select name="level" label={t("Your degree")}>
                                                        {degree.map((el) => (
                                                            <MenuItem key={el.id} value={el.id}>
                                                                {t(el.degree)}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                </Grid>

                                                <div className="col-md-1 d-flex align-items-center justify-content-center ps-sm-2">
                                                    <Button
                                                    style={{minWidth:"114px"}}
                                                        className="ms-3 ms-md-4 mt-3 mt-md-0"
                                                        type="submit"
                                                        variant="contained"
                                                        onClick={() => addSkills({ values, errors })}
                                                    >
                                                        {t("Add")}
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>

                            <div className="col-md-10 my-5" style={{ minHeight: "200px" }}>
                                <label htmlFor="list" className="form-label">
                                    {t("Lists")}
                                </label>
                                <div className="w-100 h-100 pe-3">
                                    <div className="card-brd w-100 h-100 p-3">
                                        {items.map((el, i) => (
                                            <span
                                                key={i}
                                                className="bg-secondary text-white m-1 rounded float-start d-flex"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title={el.level}
                                                onClick={(e) => showBtn(e)}
                                            >
                                                {el.name}
                                                <i onClick={removeItem} className="bi bi-trash bi-xs"></i>
                                            </span>
                                        ))}
                                    </div>
                                </div>
                                <label id="lbl-list" className="text-danger my-1">
                                    Please required list
                                </label>
                            </div>
                            <div className="col-md-1 d-flex align-items-end justify-content-center p-0">
                                <Button
                                    className="mb-3"
                                    disabled={loading}
                                    variant="contained"
                                    onClick={handleSubmit}
                                    startIcon={loading ? <CircularProgress size="0.9rem" /> : undefined}
                                >
                                    {loading ? t("Saving") :t("Save")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
