import React from "react";
import { NavLink, Route, Routes } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Education from "../components/Education/Education";
import Personal from "../components/Personal/Personal";
import WorkHistory from "../components/WorkHistory/WorkHistory";
import Contacts from "../components/Contacts/Contacts";
import styles from "./header.styles.module.css";
import Documents from "../components/Documents/Documents";
import Review from "../components/Review/Review";
import Skills from "../components/Skills/Skills";
import Finished from "../components/Finished";
import REACT_APP from "../environment";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Select } from "@mui/material";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
const Header = () => {
    const [lang, setlang] = useState(localStorage.getItem('lan') || "en");
    const [logo, setLogo] = useState(null);
    async function getLogo() {
        await fetch(`${REACT_APP.API_URL}/api/hrp/v2/onb/logo`, {
            headers: { Authorization: sessionStorage.getItem("token") },
            method: "GET",
        })
            .then((res) => res.blob())
            .then((blob) => {
                if (blob.type?.slice(0, 5) === "image") {
                    setLogo(window.URL.createObjectURL(blob));
                }
            })
            .catch((err) => console.log(err));
    }
    useEffect(() => {
        getLogo();
    }, []);

    const w3_close = () => {
        document.getElementById("mySidebar").style.display = "none";
    };

    const w3_open = () => {
        document.getElementById("mySidebar").style.display = "block";
    };
    const { t, i18n } = useTranslation();

    const onLanguage = (e) => {
        const lng = e.target.value;
        i18n.changeLanguage(lng);
        localStorage.setItem("lan", lng)
        window.location.reload()
    };
    return (
        <div style={{ overflow: "hidden" }}>
            <div id="mySidebar" className={`${styles.menu} w3-sidebar w3-bar-block w3-collapse w3-card w3-animate-left`}>
                <div style={{ display: "grid", alignItems: "center", height: "100%", paddingBottom: "200px" }}>
                    <div>
                        <button className="w3-bar-item w3-button w3-large w3-hide-large" onClick={w3_close} style={{ textAlign: "center" }}>
                            <span style={{ color: "#854248", padding: 0, margin: 0 }}> Close &times;</span>
                        </button>
                        <Select  value={localStorage.getItem("lan")||"en"} size="medium" onChange={onLanguage} style={{width:"120px", color: "white" ,marginBottom:"20px"}}>
                            <MenuItem value="en">
                                en
                            </MenuItem>
                             <MenuItem value="ru">
                                ru
                            </MenuItem>
                            <MenuItem value="uz">
                                uz
                            </MenuItem>
                        </Select>
                        <NavLink className="nav-link" to="/">
                            {t("Personal")}
                        </NavLink>
                        <NavLink className="nav-link" to="/workhistory">
                            {t("Work History")}
                        </NavLink>
                        <NavLink className="nav-link" to="/education">
                            {t("Educations")}
                        </NavLink>
                        <NavLink className="nav-link" to="/contact">
                            {t("Contacts")}
                        </NavLink>
                        <NavLink className="nav-link" to="/skill">
                            {t("Skills")}
                        </NavLink>
                        <NavLink className="nav-link" to="/document">
                            {t("Documents")}
                        </NavLink>
                        <NavLink className="nav-link" to="/review">
                            {t("Review")}
                        </NavLink>
                        
                    </div>
                </div>
            </div>

            <div className="w3-main" style={{ marginLeft: "200px" }}>
                <div className={`w3-light-gray d-flex ${styles.headTittle}`}>
                    <div>
                        <button className="w3-button w3-light-gray w3-xlarge w3-hide-large" onClick={w3_open}>
                            &#9776;
                        </button>
                    </div>
                    <div className="w3-container">
                        <h1 style={{ color: "#333" }}>OnBoarding</h1>
                    </div>
                    <div>{logo && <img style={{ margin: "auto 15px", width: "auto", height: "64px" }} src={logo} alt="logo" />}</div>
                </div>

                <div className="main w3-container">
                    <Routes>
                        <Route path="/" element={<Personal />}></Route>
                        <Route path="/workhistory" element={<WorkHistory />}></Route>
                        <Route path="/education" element={<Education />}></Route>
                        <Route path="/contact" element={<Contacts />}></Route>
                        <Route path="/skill" element={<Skills />}></Route>
                        <Route path="/document" element={<Documents />}></Route>
                        <Route path="/review" element={<Review />}></Route>
                    </Routes>
                </div>
            </div>
        </div>
    );
};

export default Header;
