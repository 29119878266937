/* eslint-disable no-unused-expressions */
import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTranslation } from 'react-i18next';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import REACT_APP from "../../environment";

import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { validateSignIn } from "../validateSchema";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { CircularProgress } from "@mui/material";

const theme = createTheme();

export default function Login() {
    let navigate = useNavigate();
    const [visibleIcon, setVisibleIcon] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const{t}=useTranslation()
    const handleSubmit = (event) => {
        setLoading(true);
        axios
            .post(`${REACT_APP.AUTH_URL}/api/hrp/v2/onboarding/login`, {
                email: event.email,
                password: event.password,
            })
            .then((res) => {
                sessionStorage.setItem("token", res.data?.data?.token);
                navigate("/");
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                toast.error(<p className="qcont">{err.response.data.errors[0].message}</p>);
            });
       
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs" className="py-5">
                <CssBaseline />
                <ToastContainer autoClose={1500} />
                <Box sx={{ marginTop: 8, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t("Sign In")}
                    </Typography>

                    <Formik
                        initialValues={{ email: "", password: "" }}
                        validationSchema={validateSignIn}
                        onSubmit={async (values) => {
                            handleSubmit(values);
                            return new Promise((res) => setTimeout(res, 500));
                        }}
                    >
                        {({ values, errors }) => (
                            <Form autoComplete="off" className="mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Field fullWidth name="email" disabled={loading} label={t('Email Address')} autoComplete="email" component={TextField} />
                                    </Grid>

                                    <Grid item xs={12} className="input_div">
                                        <Field
                                            fullWidth
                                            label={t("Password")}
                                            name="password"
                                            disabled={loading}
                                            component={TextField}
                                            autoComplete="password"
                                            type={visibleIcon ? "password" : "text"}
                                        />
                                        {visibleIcon ? (
                                            <VisibilityOffIcon onClick={() => setVisibleIcon((prev) => !prev)} />
                                        ) : (
                                            <RemoveRedEyeIcon onClick={() => setVisibleIcon((prev) => !prev)} />
                                        )}
                                    </Grid>
                                </Grid>
                                {/* <FormControlLabel
                  control={<Checkbox value="remember" disabled={loading} />}
                  label="Remember me"
                /> */}

                                <Button
                                    type="submit"
                                    fullWidth
                                    className="mt-4"
                                    disabled={loading}
                                    variant="contained"
                                    sx={{ mt: 2, mb: 2 }}
                                    startIcon={loading && <CircularProgress size="0.9rem" />}
                                >
                                    {t("Sign In")}
                                </Button>
                                {/* <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="/register" variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid> */}
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
