import React, { useEffect, useState } from "react";
import Loader from "./Loader";

const Finished = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1500);
    }, []);

    return (
        <div className="w-100 bg-white d-flex text-center align-items-center justify-content-center" style={{ height: "90vh" }}>
            {loading ? <Loader /> : <h3 style={{ color: "#3f51b5", fontWeight: "500", fontSize: "22pt" }}>Congratulation You successfully finished !</h3>}
        </div>
    );
};

export default Finished;
